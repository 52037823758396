import { useState } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import {
  loginWithEmailOTP,
  setLoginCookies,
  setPasswordUser,
  verifyOTP,
} from "../../api/auth";
import { emailLoginErrorMap } from "../../constants/constants";
import { nameSetter } from "../../redux/reducers/profile";
import { useDispatch } from "react-redux";

interface ForgotPasswordProps {
  loginMethods: {
    EMAIL: string;
    OTP: string;
  };
  setMethod: React.Dispatch<React.SetStateAction<string>>;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  loginMethods,
  setMethod,
}: ForgotPasswordProps) => {
  const time = 300;
  const [email, setEmail] = useState<string>("");
  const [next, setNext] = useState<boolean>(false);
  const [newPass, setNewPass] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState<number>(time);
  const [otp, setOTP] = useState<string>("");
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [aid, setAid] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cnfPassword, setCnfPassword] = useState<string>("");
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showCnf, setShowCnf] = useState<boolean>(false);
  const [incorrectCnf, setIncorrectCnf] = useState<boolean>(false);
  const [loginInfo, setLoginInfo] = useState<any>(false);
  const dispatch = useDispatch();

  const [incorrectPassword, setIncorrectPassword] = useState<string>("");

  const startTimer = (initialSeconds: number) => {
    if (initialSeconds > 0) {
      setTimeout(() => {
        setSeconds(initialSeconds - 1);
        startTimer(initialSeconds - 1); // Call startTimer recursively
      }, 1000);
    }
  };

  const resendOtp = async () => {
    setLoader(true);
    if (!email.trim()) {
      alert("Enter Email");
      setLoader(false);
      return;
    } else {
      localStorage.setItem("partner_email", email.trim());
      const response = await loginWithEmailOTP({ email });
      if (response?.status === 200) {
        setAid(response?.data?.data?.aid);
        setNext(true);

        startTimer(time);
        setLoader(false);
      } else {
        alert("Something went wrong please login via password");
        setLoader(false);
      }
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    if (!email.trim()) {
      alert("Enter Email");
      setLoader(false);
      return;
    } else {
      const response = await loginWithEmailOTP({ email });
      if (response?.status === 200) {
        setAid(response?.data?.data?.aid);
        setNext(true);
        startTimer(time);
        setLoader(false);
      } else {
        alert("Something went wrong please login via password");
        setLoader(false);
      }
    }
  };

  const otpHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    if (!otp.trim()) {
      alert("Enter OTP");
      setLoader(false);
      return;
    } else {
      const response = await verifyOTP({ otp, aid });

      if (response) {
        if (response.status === 200) {
          const data = response.data.data;
          setLoginInfo({
            at: data.at,
            aid: data.aid,
            secret: data.secret,
            rt: data.rt,
            isLoggedIn: true,
            pid: data.pid,
            sid: data.sid,
            user: data.user,
            profile: data.Profile[0],
          });
          dispatch(nameSetter(response.data.data.Profile[0].nam));
          setLoader(false);
          setNewPass(true);
        } else {
          setLoader(false);
          setError(emailLoginErrorMap.W_OTP);
        }
      } else {
        setError(emailLoginErrorMap.W_OTP);
        setLoader(false);
      }
    }
  };

  const handleChangePassword = async () => {
    setLoader(true);
    const response = await setPasswordUser({
      cnfPass: cnfPassword,
      passwrd: password,
      aid: aid,
    });

    if (response) {
      if (response.status === 200) {
        setLoginCookies(loginInfo);
        navigate("/dashboard");
        setLoader(false);
      } else {
        setLoader(false);
        setError(emailLoginErrorMap.W_OTP);
      }
    } else {
      alert("Something went wrong");
    }

    setLoader(false);
  };

  const validatePasswordLength = (passwrd: any) => {
    const re = /[^-\s]{8,}/;
    if (re.test(passwrd)) {
      return 1;
    } else {
      return 0;
    }
  };

  const validatePasswordNum = (passwrd: any) => {
    const re = /[0-9]/;
    if (re.test(passwrd)) {
      return 1;
    } else {
      return 0;
    }
  };

  const validatePasswordSymbol = (passwrd: any) => {
    const re = /[#?!@$%^&*-]/;
    if (re.test(passwrd)) {
      return 1;
    } else {
      return 0;
    }
  };

  const validatePassword = () => {
    return (
      validatePasswordLength(password) &&
      validatePasswordNum(password) &&
      validatePasswordSymbol(password)
    );
  };

  const changePass = (e: any) => {
    let password = e.target.value;
    if (password.length > 50) return;
    setPassword(password);
  };

  const getStrength = () => {
    return (
      ((validatePasswordLength(password) +
        validatePasswordNum(password) +
        validatePasswordSymbol(password)) *
        5) /
      3
    );
  };

  const changeCnf = (e: any) => {
    setIncorrectCnf(true);

    let cnfPassword = e.target.value;
    if (cnfPassword.length > 50) return;

    setCnfPassword(cnfPassword);

    console.log(password, cnfPassword);
    if (cnfPassword === password) {
      setIncorrectCnf(false);
    }
  };

  const getStrengthStyle = () => {
    return ["none", "weak", "weak", "moderate", "strong", "strong"][
      Math.floor(getStrength())
    ];
  };

  const getStrengthText = () => {
    return [
      "Password Strength",
      "Weak",
      "Weak",
      "Moderate",
      "Strong",
      "Strong",
    ][Math.floor(getStrength())];
  };

  const validateCnfPassword = () => {
    return password === cnfPassword;
  };

  // const cnfFocusOut = () => {
  //   setIncorrectCnf(validateCnfPassword());
  // };

  const formValidation = () => {
    return validatePassword() && validateCnfPassword();
  };

  const toggleCnf = () => {
    // this.setState({
    //   showCnf: !this.state.showCnf,
    // });

    setShowCnf(!showCnf);
  };

  return (
    <>
      {loader && <Loader />}
      <div>
        <div className="login_form_header">Reset Your Password</div>
        <div className="login_form_des">Dont worry, We got you back!!</div>
        <div
          className="login_form_des"
          style={{ cursor: "pointer", fontSize: "16px" }}
          onClick={() => setMethod(loginMethods.EMAIL)}
        >
          {" "}
          <img
            src="https://cdn.pegasus.imarticus.org/certificate-controller/backButton.svg"
            alt="eye2"
            className="show-eye"
            style={{ width: "12px" }}
          ></img>{" "}
          &nbsp; Back
        </div>

        {error && <div className="login_error">{error}</div>}

        {newPass ? (
          <>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minHeight: "85%",
                justifyContent: "flex-start",
                overflowX: "hidden",
              }}
            >
              <div className="auth-box">
                <div className="auth-ttl">Create Password</div>
                <div style={{ display: "flex" }}>
                  <div className="pass-container">
                    <input
                      type={!showPass ? "password" : "text"}
                      className="auth-inp auth-pass"
                      onChange={changePass}
                      value={password}
                    ></input>
                    <div
                      className={`line ${
                        !incorrectPassword && validatePassword()
                          ? "lgreen"
                          : "lred"
                      }`}
                    ></div>
                  </div>
                  <div
                    className="auth-inp show-btn"
                    onClick={() => {
                      setShowPass(!showPass);
                    }}
                  >
                    <img
                      src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye2.svg"
                      alt="eye2"
                      className="show-eye"
                    ></img>
                    <span>{showPass ? "Hide" : "Show"}</span>
                  </div>
                </div>
                {incorrectPassword && (
                  <div className="auth-error">Password must be valid</div>
                )}
              </div>
              <div className="password-strength">
                <div style={{ width: "75%", display: "flex" }}>
                  <div
                    className={`strength-dash ${
                      getStrength() > 0 ? "weak" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      getStrength() > 1 ? "weak" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      getStrength() > 2 ? "moderate" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      getStrength() > 3 ? "strong" : "none"
                    }`}
                  ></div>
                  <div
                    className={`strength-dash ${
                      getStrength() > 4 ? "strong" : "none"
                    }`}
                  ></div>
                </div>
                <div className={`strength-text ${getStrengthStyle()}`}>
                  {getStrengthText()}
                </div>
              </div>
              <div className="check-pass">
                <img
                  src={
                    validatePasswordLength(password)
                      ? "https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                      : "https://cdn.pegasus.imarticus.org/partnerimarticus/tick3.svg"
                  }
                  alt="tick"
                ></img>
                Atleast 8 characters
              </div>
              <div className="check-pass">
                <img
                  src={
                    validatePasswordNum(password)
                      ? "https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                      : "https://cdn.pegasus.imarticus.org/partnerimarticus/tick3.svg"
                  }
                  alt="tick"
                ></img>
                One Number
              </div>
              <div className="check-pass">
                <img
                  src={
                    validatePasswordSymbol(password)
                      ? "https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                      : "https://cdn.pegasus.imarticus.org/partnerimarticus/tick3.svg"
                  }
                  alt="tick"
                ></img>
                One Symbol
              </div>

              <div className="auth-box">
                <div className="auth-ttl">Confirm Password</div>
                <div style={{ display: "flex" }}>
                  <div className="pass-container">
                    <input
                      type={!showCnf ? "password" : "text"}
                      className="auth-inp auth-pass"
                      onChange={changeCnf}
                      value={cnfPassword}
                    ></input>
                    <div
                      className={`line ${
                        formValidation() && !incorrectCnf ? "lgreen" : "lred"
                      }`}
                    ></div>
                  </div>
                  <div className="auth-inp show-btn" onClick={toggleCnf}>
                    <img
                      src={
                        "https://cdn.pegasus.imarticus.org/partnerimarticus/eye2.svg"
                      }
                      alt="eye"
                    ></img>
                    {showCnf ? "Hide" : "Show"}
                  </div>
                </div>
                {incorrectCnf && (
                  <div className="auth-error">Password Does not match</div>
                )}
              </div>

              <button
                type="submit"
                style={{ width: "100%" }}
                className={`${formValidation() ? "" : "disable"} login_btn`}
                onClick={formValidation() ? handleChangePassword : undefined}
              >
                Log In
              </button>
            </div>
          </>
        ) : (
          <>
            {!next ? (
              <form onSubmit={submitHandler}>
                <div className="login_labels">Enter Email Id</div>

                <input
                  type="text"
                  className="login_inputs"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled={next}
                />

                <button
                  className="login_btn"
                  type="submit"
                  disabled={next}
                  style={{ width: "100%" }}
                >
                  Send OTP
                </button>
              </form>
            ) : (
              <form onSubmit={otpHandler}>
                <div className="login_labels">Enter Email Id</div>

                <input
                  type="text"
                  className="login_inputs"
                  value={email}
                  disabled={next}
                />

                <div className="login_labels">Enter OTP</div>
                {seconds && seconds > 0 ? (
                  <div className="timer">
                    Resend in: {Math.floor(seconds / 60)}:
                    {10 > seconds - Math.floor(seconds / 60) * 60 && `0`}
                    {seconds - Math.floor(seconds / 60) * 60}
                  </div>
                ) : (
                  <div className="timer">
                    <button
                      className="resendOtpBtn"
                      onClick={() => {
                        setSeconds(time);
                        resendOtp();
                      }}
                    >
                      Resend OTP
                    </button>
                  </div>
                )}

                <input
                  type="number"
                  className="login_inputs"
                  value={otp}
                  onClick={() => {
                    setError("");
                  }}
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                  disabled={!next}
                />

                <button className="login_btn" type="submit">
                  Login
                </button>
              </form>
            )}
          </>
        )}

        <div className="login_switch">
          <div
            className="login_switch_btn"
            onClick={() => setMethod(loginMethods.OTP)}
          >
            Login with OTP
          </div>
          <div
            className="login_switch_btn"
            onClick={() => setMethod(loginMethods.EMAIL)}
          >
            Login with Email
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
