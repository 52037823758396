import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

interface DashboardProps {
  permissions: Record<number, number[]> | undefined;
}

const Dashboard = ({ permissions }: DashboardProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (permissions && permissions?.[17]?.length > 0) {
      navigate("/master");
    } else if (permissions && permissions?.[2]?.length > 0) {
      navigate("/partnerLeads");
    } else if (permissions && permissions?.[9]?.length > 0) {
      navigate("/a_app");
    } else if (permissions && permissions?.[13]?.length > 0) {
      navigate("/track_o_leads");
    } else if (permissions && permissions?.[15]?.length > 0) {
      navigate("/m_b2a_partner");
    } else if (permissions && permissions?.[18]?.length > 0) {
      navigate("/u_leads");
    }
  }, [permissions, navigate]);

  return <Loader />;
};

export default Dashboard;
