import { sidebarMap } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import { checkVisibility } from "../utility/helper";

interface SideBarProps {
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
  permissions: Record<number, number[]> | undefined;
}

const SideBar: React.FC<SideBarProps> = ({
  active,
  setActive,
  permissions,
}: SideBarProps) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <img
        src="https://cdn.pegasus.imarticus.org/labs/sidebar-logo.svg"
        alt="logo"
        className="sidelogo"
      />

      <div className="sidebar_list">
        {checkVisibility({ permissions: permissions, resource: 17 }) && (
          <div
            onClick={() => {
              navigate("/master");
            }}
          >
            <img
              src="https://cdn.pegasus.imarticus.org/Aap-landing-page/images/arrow.svg"
              alt="logo"
              style={{ width: "10px" }}
            />
            <span className="ml-1">Back</span>
          </div>
        )}
        {/* <div
          className={active === sidebarMap.DASHBOARD ? "sidebar_active" : ""}
          onClick={() => {
            setActive(sidebarMap.DASHBOARD);
            navigate("/dashboard");
          }}
        >
          Dashboard
        </div> */}

        {/* {checkVisibility({ permissions: permissions, resource: 1 }) && (
          <div
            className={
              active === sidebarMap.TRACK_LEADS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.TRACK_LEADS);
              navigate("/trackLeads");
            }}
          >
            Track Leads
          </div>
        )} */}

        {checkVisibility({ permissions: permissions, resource: 2 }) && (
          <div
            className={
              active === sidebarMap.PARTNER_LEADS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.PARTNER_LEADS);
              navigate("/partnerLeads");
            }}
          >
            View Partner Leads
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 5 }) && (
          <div
            className={
              active === sidebarMap.TRACK_APPLICATION ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.TRACK_APPLICATION);
              navigate("/m_application");
            }}
          >
            Track Applications
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 3 }) && (
          <div
            className={
              active === sidebarMap.APPLICATIONS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.APPLICATIONS);
              navigate("/application");
            }}
          >
            Applications Forms
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 4 }) && (
          <div
            className={
              active === sidebarMap.MANAGE_PARTNERS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.MANAGE_PARTNERS);
              navigate("/m_partner");
            }}
          >
            Manage Partners
          </div>
        )}

        {/* {checkVisibility({ permissions: permissions, resource: 6 }) && (
          <div
            className={
              active === sidebarMap.MANAGE_INVOICES ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.MANAGE_INVOICES);
              navigate("/m_invoices");
            }}
          >
            Manage Invoices
          </div>
        )} */}

        {checkVisibility({ permissions: permissions, resource: 7 }) && (
          <div
            className={
              active === sidebarMap.MANAGE_SALE_GROUP ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.MANAGE_SALE_GROUP);
              navigate("/m_s_grp");
            }}
          >
            Manage Sales Group
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 8 }) && (
          <div
            className={
              active === sidebarMap.MANAGE_TYPES ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.MANAGE_TYPES);
              navigate("/m_typ");
            }}
          >
            Manage Types
          </div>
        )}

        {/* {checkVisibility({ permissions: permissions, resource: 9 }) && (
          <div
            className={
              active === sidebarMap.FILL_APPLICATION ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.FILL_APPLICATION);
              navigate("/fill_application");
            }}
          >
            Fill Application
          </div>
        )} */}

        {checkVisibility({ permissions: permissions, resource: 13 }) && (
          <div
            className={
              active === sidebarMap.TRACK_PARTNER_SIDE_LEADS
                ? "sidebar_active"
                : ""
            }
            onClick={() => {
              setActive(sidebarMap.TRACK_PARTNER_SIDE_LEADS);
              navigate("/track_o_leads");
            }}
          >
            Track Leads
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
