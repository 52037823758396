import { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import { sidebarMap } from "../../constants/constants";
import { FormBlocks } from "../../types";
import {
  editApplication,
  getFormBlocks,
  getSingleApplication,
} from "../../api/partner";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

interface EditApplicationProps {
  permissions: Record<number, number[]> | undefined;
}

const EditApplication: React.FC<EditApplicationProps> = ({
  permissions,
}: EditApplicationProps) => {
  const [active, setActive] = useState<string>(sidebarMap.APPLICATIONS);
  const [name, setName] = useState<string>("");
  const [form, setForm] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<FormBlocks[]>([]);
  const [stageForm, setStageForm] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  let id = params.get("id");

  useEffect(() => {
    setLoading(true);
    if (id) {
      const applicationLookup = async () => {
        const response = await getSingleApplication({ id });

        if (response?.status === 200) {
          const app = response?.data?.data?.application;
          console.log(app);
          setName(app.name);
          setVersion(app.version);
          const prev: string[] = [];
          if (app?.app_block_schema_ids) {
            app.app_block_schema_ids.forEach((elem: { _id: string }) => {
              prev.push(elem._id);
            });
          }
          setForm(prev);
        }
      };

      applicationLookup();
    } else {
      navigate("/application");
    }

    const gettingFormBlocks = async () => {
      const response = await getFormBlocks();

      if (response?.status === 200) {
        setBlocks(response?.data?.data?.formBlocks);
      }
      setLoading(false);
    };

    gettingFormBlocks();
  }, [id, navigate]);

  const createForm = () => {
    if (form.includes(stageForm)) {
      alert("Form Block Already included");
    } else {
      setForm([...form, stageForm]);
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    let tname = name.trim();
    if (!tname) {
      alert("Please fill Name");
      setLoading(false);
      return;
    } else if (!form.length) {
      alert("Form cannot be empty");
      setLoading(false);
      return;
    } else {
      const response = await editApplication({ id, name, form });

      if (response?.status === 200) {
        setLoading(false);
        alert("Application Edited!");
        navigate("/application");
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <SideBar
        permissions={permissions}
        setActive={setActive}
        active={active}
      />

      <TopBar active={active} />
      <div className="main">
        <div className="partner_leads">
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div>
                  <button
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="back_btn"
                  >
                    Back
                  </button>
                </div>
                <form onSubmit={submitHandler} className="fillup_form">
                  <div className="partner_leads_header">
                    Edit Application Form Version: V_{version}
                  </div>
                  <hr />
                  <div className="fillup_form_field">
                    <div>Name</div>
                    <input
                      type="text"
                      className="fillup_form_inputs w-78"
                      placeholder="Type Name Here..."
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="fillup_form_field">
                    <div>Select Form Blocks</div>
                    <select
                      className="fillup_form_inputs w-78"
                      style={{ background: "white" }}
                      onChange={(e) => {
                        setStageForm(e.target.value);
                      }}
                    >
                      <option value="">Select Form .....</option>

                      {blocks.length > 0 &&
                        blocks.map((elem, i) => {
                          return (
                            <option key={i} value={elem._id}>
                              {elem.schemaName}
                            </option>
                          );
                        })}
                    </select>

                    {stageForm && (
                      <span className="addBlock" onClick={createForm}>
                        Add +
                      </span>
                    )}
                  </div>

                  <button type="submit" className="login_btn w30">
                    Submit
                  </button>
                </form>
                {form.length > 0 && (
                  <div className="final_form">
                    <h2>Final Form Sections</h2>
                    <div>
                      <div>
                        {form.map((elem) => {
                          const fblock = blocks.find(
                            (item) => item._id === elem
                          );
                          return (
                            <div className="fblock">
                              <span>{fblock?.schemaName}</span>
                              <img
                                src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                                alt="cross"
                                onClick={() => {
                                  const updatedForm = form.filter(
                                    (item) => item !== elem
                                  );
                                  setForm(updatedForm);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default EditApplication;
