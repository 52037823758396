import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSingleApplication, uploadFormFiles } from "../../api/partner";
import { Application } from "../../types";
import Form from "@rjsf/core";

import validator from "@rjsf/validator-ajv8";
import { RegistryWidgetsType } from "@rjsf/utils";
import Cookies from "universal-cookie";
import Loader from "../../components/Loader";

interface ViewApplicationProps {
  permissions: Record<number, number[]> | undefined;
}

const ViewSingleApplication: React.FC<ViewApplicationProps> = ({
  permissions,
}: ViewApplicationProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<Application | null>(null);
  const [slug, setSlug] = useState<string>("");
  const [allSlug, setAllSlug] = useState<string[]>([]);

  const imgs = [
    "https://cdn.pegasus.imarticus.org/partnerimarticus/person.svg",
    "https://cdn.pegasus.imarticus.org/partnerimarticus/bag.svg",
    "https://cdn.pegasus.imarticus.org/partnerimarticus/document.svg",
  ];

  const params = new URLSearchParams(window.location.search);
  let id = params.get("id");

  useEffect(() => {
    setLoading(true);
    if (id) {
      const applicationLookup = async () => {
        const response = await getSingleApplication({ id });

        if (response?.status === 200) {
          let blocks = response?.data?.data?.application?.app_block_schema_ids;
          setApplication(response?.data?.data?.application);
          setSlug(blocks[0]?.stageSlug);

          const allStages = blocks?.map((elem: any) => {
            return elem.stageSlug;
          });

          setAllSlug(allStages);
        }

        setLoading(false);
      };

      applicationLookup();
    } else {
      navigate("/application");
    }
  }, [id, navigate]);

  const handleNext = () => {
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (allSlug.length - 1 > avail) {
        setSlug(allSlug[avail + 1]);
      }
    }
  };

  const handlePrev = () => {
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (avail > 0) {
        setSlug(allSlug[avail - 1]);
      } else {
        navigate(-1);
      }
    }
  };

  const checkNext = () => {
    let check = true;
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (allSlug.length - 1 > avail) {
        return true;
      } else {
        return false;
      }
    }

    return check;
  };

  const FileUpload = (props: any) => {
    const { value, onChange } = props;

    const [load, setLoad] = useState<boolean>(false);
    const handleChange = async (e: any) => {
      let cookies = new Cookies();
      const formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      formdata.append("aid", cookies.get("aid"));
      setLoad(true);
      const res = await uploadFormFiles(formdata);
      if (res?.status === 200) {
        props.onChange(res.data.data.url);
      } else {
        alert("Image Size Exceeded, It should be under 80KB");
      }
      setLoad(false);
    };

    console.log(value);
    return (
      <span className="uploadPic">
        {load ? (
          <Loader />
        ) : (
          <>
            <label className="customUpload">
              <span className="uploadtext-1">Upload</span>
              <span className="uploadtext-2">&nbsp; files here</span>

              <span className="smallText_uploadfile ml-1">
                (File size should not exceed 5 MB. Document should be in PDF,
                JPEG, JPG format only.)
              </span>
              <input
                type={"file"}
                style={{ display: "none" }}
                onChange={handleChange}
                disabled
              ></input>
            </label>
            {value && (
              <div className="fileName">
                <a
                  href={value || ""}
                  target="_blank"
                  className="fileName"
                  rel="noreferrer"
                >
                  {value ? (
                    <>{new URL(value).pathname.replace("/", "")}</>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            )}

            {value && (
              <button
                className="fileRemove"
                onClick={() => {
                  onChange(undefined);
                }}
              >
                Remove
              </button>
            )}
          </>
        )}
      </span>
    );
  };

  const widgets: RegistryWidgetsType = {
    FileWidget: FileUpload,
  };

  return (
    <div className="v_application">
      {loading && !application ? (
        <Loader />
      ) : (
        <>
          {/* TopBar */}
          <div className="v_application_topbar">
            <img
              src="https://cdn.pegasus.imarticus.org/partnerimarticus/logo1.svg"
              alt="logo"
            />
          </div>
          <div className="v_application_main">
            <div
              className="v_a_back"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </div>

            <div className="v_a_header">
              <div className="v_a_header1">APPLICATION FORM</div>
              <div className="v_a_header2">{application?.name}</div>
              <div className="v_a_header3">Help us to know more about you!</div>
            </div>

            <div className="v_topnav">
              {application?.app_block_schema_ids.map((elem, i) => {
                return (
                  <div
                    className={`v_t_` + ((i % 3) + 1)}
                    key={i}
                    onClick={() => {
                      setSlug(elem.stageSlug);
                    }}
                  >
                    <img src={imgs[i]} alt="person" />
                    &nbsp; {elem.schemaDisplayName}
                  </div>
                );
              })}

              {/* <div className="v_t_2">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/bag.svg"
                  alt="bag"
                />
                &nbsp; Experience
              </div> */}
              {/* <div className="v_t_3">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/document.svg"
                  alt="doc"
                />
                &nbsp; Document to Upload
              </div> */}
            </div>

            <div className="v_a_main_form">
              {application?.app_block_schema_ids.map((elem, i) => {
                return (
                  <>
                    {slug === elem.stageSlug ? (
                      <>
                        <Form
                          schema={elem.Edituischema}
                          uiSchema={elem.Viewuischema}
                          validator={validator}
                          key={i}
                          disabled
                          widgets={widgets}
                        />

                        <div className="form_navigation">
                          <button className="form_back" onClick={handlePrev}>
                            Previous
                          </button>

                          {checkNext() ? (
                            <button className="form_next" onClick={handleNext}>
                              Next
                            </button>
                          ) : (
                            <button
                              className="form_next"
                              onClick={() => console.log("yes")}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewSingleApplication;
