import { useEffect, useState } from "react";
import { createApplication, getFormBlocks } from "../../api/partner";
import { FormBlocks } from "../../types";
import { pageOptions } from "../../constants/constants";
import Loader from "../Loader";

interface CreateApplicationProps {
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

const CreateApplication: React.FC<CreateApplicationProps> = ({
  setTab,
}: CreateApplicationProps) => {
  const [name, setName] = useState<string>("");
  const [form, setForm] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<FormBlocks[]>([]);
  const [stageForm, setStageForm] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    const gettingFormBlocks = async () => {
      const response = await getFormBlocks();

      if (response?.status === 200) {
        setBlocks(response?.data?.data?.formBlocks);
      }
      setLoading(false);
    };

    gettingFormBlocks();
  }, []);

  const createForm = () => {
    if (form.includes(stageForm)) {
      alert("Form Block Already included");
    } else {
      setForm([...form, stageForm]);
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let tname = name.trim();
    if (!tname) {
      alert("Please fill Name");
      return;
    } else if (!form.length) {
      alert("Form cannot be empty");
      return;
    } else {
      const response = await createApplication({ setLoading, name, form });

      if (response?.status === 200) {
        alert("Application Created!");

        setTab(pageOptions.VIEW);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <button
              onClick={() => {
                setTab(pageOptions.VIEW);
              }}
              className="back_btn"
            >
              Back
            </button>
          </div>
          <form onSubmit={submitHandler} className="fillup_form">
            <div className="partner_leads_header">Create Application Form</div>
            <hr />
            <div className="fillup_form_field">
              <div>Name</div>
              <input
                type="text"
                className="fillup_form_inputs w-78"
                placeholder="Type Name Here..."
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            <div className="fillup_form_field">
              <div>Select Form Blocks</div>
              <select
                className="fillup_form_inputs w-78"
                style={{ background: "white" }}
                onChange={(e) => {
                  setStageForm(e.target.value);
                }}
              >
                <option value="">Select Form .....</option>

                {blocks.length > 0 &&
                  blocks.map((elem, i) => {
                    return (
                      <option key={i} value={elem._id}>
                        {elem.schemaName}
                      </option>
                    );
                  })}
              </select>

              {stageForm && (
                <span className="addBlock" onClick={createForm}>
                  Add +
                </span>
              )}
            </div>

            <button type="submit" className="login_btn w30">
              Submit
            </button>
          </form>
          {form.length > 0 && (
            <div className="final_form">
              <h2>Final Form Sections</h2>
              <div>
                <div>
                  {form.map((elem) => {
                    const fblock = blocks.find((item) => item._id === elem);
                    return (
                      <div className="fblock">
                        <span>{fblock?.schemaName}</span>
                        <img
                          src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                          alt="cross"
                          onClick={() => {
                            const updatedForm = form.filter(
                              (item) => item !== elem
                            );
                            setForm(updatedForm);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateApplication;
