import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Tooltip,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getAllPartnerTypes,
  getApplications,
  getPartnerLeads,
  sendApplicationtoUser,
  sendReminder,
} from "../../api/partner";
import { ApplicationInfos, Lead, PartnerTypes, SalesGroup } from "../../types";
import { useNavigate } from "react-router-dom";
import {
  applicationStage,
  applicationStagetoNumber,
} from "../../constants/constants";
import Loader from "../Loader";
import { CountryCodes, LEADSQUARE_STATE_LOCATIONS } from "../../utility/utils";
import { getDate } from "../../utility/helper";
const configs = require("../../configs/configs");

interface SentApplicationProps {
  setSent: React.Dispatch<React.SetStateAction<number>>;
  setReview: React.Dispatch<React.SetStateAction<number>>;
}

const SentApplication = ({ setSent, setReview }: SentApplicationProps) => {
  const [totalCount, setTotalCount] = useState(0); // Total number of rows
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<ApplicationInfos[]>([]);
  const [sOption, setsOption] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();

  const [create, setCreate] = useState<boolean>(false);

  const [location, setLocation] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [ccod, setCCOD] = useState<string>("91");
  const [mobile, setMobile] = useState<string>("");
  const [partnerTypes, setPartnerTypes] = useState<PartnerTypes[]>([]);

  const [trigger, setTrigger] = useState<boolean>(false);
  const [partnerType, setPartnerType] = useState<string>("");
  const [targetLead, setTargetLead] = useState<string>("");

  const [apEmail, setAPEmail] = useState<string>("");

  const [leadSource, setLeadSource] = useState<string>("");
  const [leads, setLeads] = useState<Lead[]>([]);
  const [ptDisplay, setPtDisplay] = useState<string>("");
  const [ptForm, setPTForm] = useState<string>("");
  const [ptFormName, setPTFormName] = useState<string>("");
  const [displayEmail, setParentDPEmail] = useState<string>("");
  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");
  const [qstatus, setQstatus] = useState<string>("");
  const [qType, setQType] = useState<string>("");

  const [reminderBox, setReminderBox] = useState<boolean>(false);
  const [successBox, setSuccessBox] = useState<boolean>(false);
  const [successAppBox, setSuccessAppBox] = useState<boolean>(false);
  const [reminderAid, setReminderAid] = useState<string>("");

  const [qSG, setqSG] = useState<string>("");
  const [allSgs, setAllSgs] = useState<SalesGroup[]>([]);

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    const getAllPartnerLeads = async () => {
      const response = await getPartnerLeads({
        rowsPerPage,
        page,
        status: 1,
      });

      if (response?.status === 200) {
        let value = response?.data?.data;
        setLeads(value.leads);
        setTotalCount(value.totalLeads);
        // setLeadStatus(value.leadStatus);
      }
      setLoading(false);
    };

    getAllPartnerLeads();
  }, [page, rowsPerPage, trigger]);

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getAllPartnerTypes({ rowsPerPage: 0, page: 0 });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setPartnerTypes(value.partnerTypes);
      }
    };
    allTypes();
  }, []);

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getApplications({
        rowsPerPage,
        page,
        status: [
          applicationStagetoNumber.SENT,
          applicationStagetoNumber.FORM_FILLING,
          applicationStagetoNumber.FLAGGED_FINANCE,
          applicationStagetoNumber.FLAGGED_ADMIN,
        ],
        qstatus,
        qType,
        sDate,
        eDate,
        sOption,
        search,
        qSG,
      });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setTotalCount(value.totalapplications);
        setApplication(value.applications);
        setReview(value.allReview);
        setSent(value.allSent);
        setAllSgs(value.sgNames);
      }

      setLoading(false);
    };

    allTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, trigger, qType, qstatus, eDate, qSG]);

  const empty = () => {
    setName("");
    setMobile("");
    setEmail("");
    setLocation("");
    setState("");
    setCCOD("");
    setTargetLead("");
    setPartnerType("");
    setAPEmail("");
    setLeadSource("");
    setPtDisplay("");
    setPTForm("");
    setPTFormName("");
    setParentDPEmail("");
  };

  const sendApplicationHandler = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (
      !name?.trim() ||
      !email?.trim() ||
      !ccod?.trim() ||
      !state?.trim() ||
      !mobile?.trim() ||
      !location?.trim() ||
      !partnerType?.trim()
    ) {
      alert("All fields are mandatory");
      return;
    } else {
      setLoading(true);
      const response = await sendApplicationtoUser({
        partner_lead_id: targetLead,
      });

      console.log(response);

      if (response?.status === 200) {
        if (response?.data?.data?.otp) {
          setAPEmail(response?.data?.data?.email);
        } else {
          setSuccessAppBox(true);
          empty();
          setTrigger(!trigger);
          setCreate(false);
        }
      } else if (response?.status === 420) {
        setCreate(false);
        empty();
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {reminderBox && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <div className="errBox_Head">
                    Are you sure you want to send reminder to partner?
                  </div>
                  <div className="errBox_Body">
                    By confirming a reminder will be sent to partner to complete
                    & submit the application form
                  </div>
                  <div className="errBox_btn">
                    <button
                      className="err_no"
                      onClick={() => {
                        setReminderBox(false);
                        setReminderAid("");
                      }}
                    >
                      No
                    </button>
                    <button
                      className="err_yes"
                      onClick={async () => {
                        setLoading(true);
                        await sendReminder(reminderAid);
                        setLoading(false);
                        setTrigger(!trigger);
                        setReminderBox(false);
                        setSuccessBox(true);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {successBox && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tick2.svg"
                    alt="logo"
                    style={{ width: "50px" }}
                  />
                  <div className="errBox_Head mt-1">
                    The reminder has been sent to partner successfully!
                  </div>

                  <div className="errBox_btn">
                    <button
                      className="err_yes"
                      onClick={async () => {
                        setSuccessBox(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {successAppBox && (
            <div className="errBox_container">
              <div className="errBox_container_box w-copy-link">
                <div className="errBox_main">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tick2.svg"
                    alt="logo"
                    style={{ width: "50px" }}
                  />
                  <div className="errBox_Head mt-1">
                    Application link has been sent to the partner
                  </div>

                  <div className="copyLink">
                    <span>{configs.routes.WEBSITE_LINK}</span>

                    <img
                      src="https://cdn.pegasus.imarticus.org/certificate-controller/copy-content.svg"
                      alt="logo"
                      style={{ width: "20px", cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          configs.routes.WEBSITE_LINK
                        );

                        alert("Link Copied");
                      }}
                    />
                  </div>

                  <div className="errBox_btn ">
                    <button
                      className="err_yes"
                      onClick={async () => {
                        setSuccessAppBox(false);
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="filters-4">
            <div>
              <div className="filter_headers">Partner Type</div>
              <select
                className="filter_inputs"
                value={qType}
                onChange={(e) => {
                  setQType(e.target.value);
                }}
              >
                <option value="">All</option>

                {partnerTypes &&
                  partnerTypes.map((elem, i) => {
                    return <option value={elem._id}>{elem.name}</option>;
                  })}
              </select>
            </div>
            <div>
              <div className="filter_headers">Sales Group</div>
              <select
                className="filter_inputs"
                value={qSG}
                onChange={(e) => {
                  setqSG(e.target.value);
                }}
              >
                <option value="">All</option>

                {allSgs &&
                  allSgs.length > 0 &&
                  allSgs.map((elem, i) => {
                    if (elem._id !== "*") {
                      return (
                        <option value={elem._id} key={i}>
                          {elem.name}
                        </option>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </select>
            </div>
            <div>
              <div className="filter_headers">Application Status</div>
              <select
                className="filter_inputs"
                value={qstatus}
                onChange={(e) => {
                  setQstatus(e.target.value);
                }}
              >
                <option value="">All</option>
                <option value="1">Not Started</option>
                <option value="2">In Progress</option>
                <option value="6">Flagged</option>
              </select>
            </div>
            <div>
              <div className="filter_headers">From Date</div>
              <input
                value={sDate}
                onChange={(e) => {
                  setsDate(e.target.value);
                }}
                type="date"
                className="filter_inputs"
              />
            </div>
            <div>
              <div className="filter_headers">To Date</div>
              <input
                type="date"
                className="filter_inputs"
                value={eDate}
                disabled={sDate ? false : true}
                onChange={(e) => {
                  seteDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="application_header" style={{ marginTop: "1rem" }}>
            All Applications
            <Tooltip title="Here we can track the application status for Partners with whom Form is shared">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                alt="profile"
                className="ml-1"
              />
            </Tooltip>
          </div>
          <hr />
          <div className="search_newapp mt-1">
            <div className="flex">
              <div>
                <select
                  name="cars"
                  id="cars"
                  className="search_filters"
                  value={sOption}
                  onChange={(e) => {
                    setsOption(e.target.value);
                  }}
                >
                  <option value="">Select Search Via</option>
                  <option value="UID">Search Via UID</option>
                  <option value="EMAIL">Search Via Email ID</option>
                  <option value="MOBILE">Search Via Mobile Number</option>
                </select>
              </div>
              <div>
                {sOption && (
                  <input
                    type="text"
                    className="search_filters ml-1 ph-1"
                    value={search}
                    placeholder={`Enter ${sOption}`}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                )}

                {search && search.trim() && (
                  <button
                    className="form_back ml-1"
                    onClick={() => {
                      setTrigger(!trigger);
                    }}
                  >
                    Search
                  </button>
                )}

                <button
                  className="form_back ml-1"
                  onClick={() => {
                    setSearch("");
                    setQType("");
                    setQstatus("");
                    setsDate("");
                    seteDate("");
                    setsOption("");
                    setqSG("");
                    setTrigger(!trigger);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <button
              className="back_btn col-green"
              onClick={() => {
                setCreate(true);
              }}
            >
              New Application
            </button>
          </div>
          {create && (
            <div className="popupModal">
              <div className="popupModal_main">
                <div className="popupModal_header">
                  <span>New Application </span>
                  <img
                    src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                    alt="cross"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCreate(false);
                      empty();
                    }}
                  />
                </div>
                <hr />
                <div className="popupModal_body">
                  <form onSubmit={sendApplicationHandler}>
                    <div className="popupModal_form">
                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Partner Full Name <span className="red">*</span>
                        </span>

                        <Autocomplete
                          sx={{
                            width: "95%",
                          }}
                          options={leads}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                          onChange={(e, option) => {
                            const selected = leads.filter(
                              (elem) => elem._id === option?._id
                            );
                            if (selected && selected?.length) {
                              setTargetLead(selected[0]._id);
                              setName(selected[0].name);
                              setEmail(selected[0].email);
                              setCCOD(selected[0].ccod);
                              setMobile(selected[0].phone);
                              setLocation(selected[0].city);
                              setState(selected[0].state);
                              setPartnerType(selected[0].partner_type);
                              setAPEmail(selected[0].parent_aid);
                              setLeadSource(selected[0].lead_source);
                              setParentDPEmail(selected[0].p_email);

                              const pt = partnerTypes.filter(
                                (elem) => elem._id === selected[0].partner_type
                              );

                              setPtDisplay(pt[0].name);
                              setPTForm(pt[0].form_id);
                              setPTFormName(pt[0].form_name);
                            } else {
                              console.log("ytes");
                              setName("");
                              setEmail("");
                              setCCOD("");
                              setMobile("");
                              setLocation("");
                              setState("");
                              setPartnerType("");
                              setAPEmail("");
                              setLeadSource("");
                              setPtDisplay("");
                              setPTForm("");
                              setParentDPEmail("");
                              setPTFormName("");
                            }
                          }}
                        />

                        {/* <select
                          className="popupModal_input"
                          onChange={(e) => {
                            const selected = leads.filter(
                              (elem) => elem._id === e.target.value
                            );
                            if (selected && selected?.length) {
                              setTargetLead(selected[0]._id);
                              setName(selected[0].name);
                              setEmail(selected[0].email);
                              setCCOD(selected[0].ccod);
                              setMobile(selected[0].phone);
                              setLocation(selected[0].city);
                              setState(selected[0].state);
                              setPartnerType(selected[0].partner_type);
                              setAPEmail(selected[0].parent_aid);
                              setLeadSource(selected[0].lead_source);
                              setParentDPEmail(selected[0].p_email);

                              const pt = partnerTypes.filter(
                                (elem) => elem._id === selected[0].partner_type
                              );

                              setPtDisplay(pt[0].name);
                              setPTForm(pt[0].form_id);
                              setPTFormName(pt[0].form_name);
                            } else {
                              console.log("ytes");
                              setName("");
                              setEmail("");
                              setCCOD("");
                              setMobile("");
                              setLocation("");
                              setState("");
                              setPartnerType("");
                              setAPEmail("");
                              setLeadSource("");
                              setPtDisplay("");
                              setPTForm("");
                              setParentDPEmail("");
                              setPTFormName("");
                            }
                          }}
                          required={true}
                          value={targetLead}
                        >
                          <option value="">Select Partner</option>
                          {leads &&
                            leads.map((elem, i) => {
                              return (
                                <option key={i} value={elem._id}>
                                  {elem.name}
                                </option>
                              );
                            })}
                        </select> */}
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Enter Email ID <span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          required={true}
                          value={email}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields popupModal_ccod_wrapper">
                        <span className="popupModal_label">
                          Phone Number <span className="red">*</span>
                        </span>
                        <div className="popupModal_ccod">
                          <select
                            className="popupModal_input ccod_input"
                            value={ccod}
                            required={true}
                            disabled
                          >
                            <option value="">Country</option>
                            {CountryCodes &&
                              CountryCodes.map((elem, i) => {
                                return (
                                  <option key={i} value={elem.value}>
                                    {elem.display}
                                  </option>
                                );
                              })}
                          </select>

                          <input
                            type="number"
                            required={true}
                            value={mobile}
                            disabled
                            maxLength={15}
                            minLength={7}
                            className="popupModal_input mob_input"
                          />
                        </div>
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Location <span className="red">*</span>
                        </span>

                        <input
                          type="text"
                          className="popupModal_input"
                          required={true}
                          value={location}
                          disabled
                        />
                        {/* <select
                          className="popupModal_input "
                          value={location}
                          disabled
                          required={true}
                        >
                          <option value="">Select Location</option>
                          {locations &&
                            locations.map((elem, i) => {
                              return (
                                <option value={elem} key={i}>
                                  {elem}
                                </option>
                              );
                            })}
                        </select> */}
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          State <span className="red">*</span>
                        </span>

                        <select
                          className="popupModal_input "
                          value={state}
                          disabled
                          required={true}
                        >
                          <option value="">Select State</option>
                          {LEADSQUARE_STATE_LOCATIONS &&
                            LEADSQUARE_STATE_LOCATIONS.map((elem, i) => {
                              return (
                                <option value={elem} key={i}>
                                  {elem}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Type<span className="red">*</span>
                        </span>
                        <input
                          type="text"
                          className="popupModal_input"
                          required={true}
                          value={ptDisplay}
                          disabled
                        />
                      </div>

                      {apEmail && (
                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Associated Partner Email{" "}
                            <span className="red">*</span>
                          </span>
                          <input
                            type="email"
                            className="popupModal_input"
                            value={displayEmail}
                            disabled
                          />
                        </div>
                      )}

                      {leadSource && (
                        <div className="popupModal_fields">
                          <span className="popupModal_label">
                            Lead Source <span className="red">*</span>
                          </span>
                          <input
                            type="text"
                            className="popupModal_input"
                            value={leadSource}
                            disabled
                          />
                        </div>
                      )}

                      {ptForm && ptFormName && (
                        <div className="popupModal_fields-3">
                          <div className="popupModal_fields-2">
                            <span className="popupModal_label">
                              Select Application Form{" "}
                              <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              value={ptFormName}
                              disabled
                            />
                          </div>
                          <div className="eye_div">
                            <img
                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye2.svg"
                              alt="eye2"
                              className="eye2"
                              onClick={() => {
                                navigate("/v_application?id=" + ptForm);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="popupModal_fields"></div>
                    </div>

                    <div className="popupModal_foot">
                      <button className="pm_create" type="submit">
                        Create
                      </button>
                      <button
                        className="pm_create pm_cancel"
                        onClick={() => {
                          setCreate(false);
                          empty();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {application && application.length > 0 ? (
            <div>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 150]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

              <TableContainer
                component={Paper}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Table>
                  <TableHead className="table_head ">
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      S. No.
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Application Form Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner UID
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Email ID
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Mobile Number
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Partner Type
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Sales Group
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Application Shared On
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Application Status
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Application Completion %
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Associate Partner
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Last Updated
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Version
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Actions
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {application &&
                      application.length > 0 &&
                      application.map((elem, i) => {
                        const last_reminder = elem.last_reminder;
                        const newDate = new Date().getTime();

                        let dontAllow = false;

                        if (newDate - last_reminder < 86400000) {
                          dontAllow = true;
                        }

                        return (
                          <TableRow className="table_row" key={i}>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {i + 1}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                              // className="link"
                              // onClick={() => {
                              //   navigate(
                              //     "/v_application?id=" + elem.application_id
                              //   );
                              // }}
                              >
                                {elem.p_a_name}
                              </span>
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_uid}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_name}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_email}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_mobile}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_type}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.sg && elem.sg.length > 0 ? (
                                <>
                                  {elem.sg.map((sg, i) => {
                                    return (
                                      <div key={i} className="sg_users">
                                        {sg}
                                        <br />
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {getDate(elem?.createdAt)}{" "}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ minWidth: "100px" }}
                              className={
                                elem.application_stage === 2 ||
                                elem.application_stage === 1
                                  ? "trb pending_yellow"
                                  : "trb flagged_red"
                              }
                            >
                              {applicationStage[elem.application_stage]}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.percent ? elem.percent + "%" : "-"}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.parent_email ? elem.parent_email : "-"}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {getDate(elem?.updatedAt)}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              V_{elem.version}{" "}
                            </TableCell>

                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.user_response && (
                                <button
                                  className="table_edit table_view light_green"
                                  style={{ padding: "6px" }}
                                  onClick={() => {
                                    navigate(
                                      "/r_application?id=" +
                                        elem.application_id +
                                        "&aid=" +
                                        elem.partner_aid
                                    );
                                  }}
                                >
                                  View Application
                                </button>
                              )}
                              <Tooltip
                                title={
                                  elem.last_reminder
                                    ? `Last Reminder Sent On ` +
                                      new Date(last_reminder)
                                    : `No reminder sent yet`
                                }
                              >
                                <button
                                  className="table_edit table_view white_green"
                                  style={{ padding: "6px" }}
                                  disabled={dontAllow}
                                  onClick={async () => {
                                    setReminderAid(elem.partner_aid);
                                    setReminderBox(true);
                                  }}
                                >
                                  Send Reminder{" "}
                                </button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="no_application">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                alt="profile"
                className=""
              />
              <span className="na_head">It's empty here!</span>
              <span className="na_desc">
                Click on New Applications to map the existing application with a
                partner
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SentApplication;
