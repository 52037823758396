// store/store.ts
import { configureStore } from "@reduxjs/toolkit";
import ProfileReducer from "./reducers/profile";

const store = configureStore({
  reducer: {
    profile: ProfileReducer,
  },
});

// Define the RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
