import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import { partnerStatus, sidebarMap } from "../../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  adminOtp,
  adminOTPBluk,
  getB2APartners,
  uploadPartners,
  verifyOTPandCreatePartner,
} from "../../../api/partner";
import { BLeaders, Partners } from "../../../types";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { checkDeepVisibility, getDate } from "../../../utility/helper";
import SideBarB2A from "../../../components/SidebarB2A";
import {
  CountryCodes,
  LEADSQUARE_CENTER_LOCATIONS,
  LEADSQUARE_STATE_LOCATIONS,
} from "../../../utility/utils";

interface ManageB2APartnersProps {
  permissions: Record<number, number[]> | undefined;
}

const ManageB2APartners: React.FC<ManageB2APartnersProps> = ({
  permissions,
}: ManageB2APartnersProps) => {
  const [active, setActive] = useState(sidebarMap.B2A_MANAGE_PARTNER);
  const [loading, setLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<Partners[]>([]);
  const [sOption, setsOption] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [trigger, setTrigger] = useState<boolean>(false);
  const [qLeader, setqLeader] = useState<string>("");
  const [qstatus, setQstatus] = useState<string>("");

  const [leaders, setLeaders] = useState<BLeaders[]>([]);
  const [admins, setAdmins] = useState<BLeaders[]>([]);

  const [create, setCreate] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subSource, setSubSource] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [ccod, setCCOD] = useState<string>("91");
  const [leader, setLeader] = useState<string>("");
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [otp, setOTP] = useState<string>("");
  const [emailList, setEmailList] = useState<string[]>([]);
  const [admin, setAdmin] = useState<string>("");
  const [adminE, setAdminE] = useState<string>("");

  const [bulk, setBulk] = useState<boolean>(false);
  const [bulkInfo, setBulkInfo] = useState<boolean>(false);
  const [file, setFile] = useState("");

  const [error, setError] = useState("");
  const time = 300;
  const [seconds, setSeconds] = useState<number>(time);

  const startTimer = (initialSeconds: number) => {
    if (initialSeconds > 0) {
      setTimeout(() => {
        setSeconds(initialSeconds - 1);
        startTimer(initialSeconds - 1); // Call startTimer recursively
      }, 1000);
    }
  };

  const handleFile = (e: any) => {
    let file = e.target.files[0];
    if (file === undefined) return;
    setFile(file);
  };

  const empty = () => {
    setName("");
    setMobile("");
    setEmail("");
    setLocation("");
    setState("");
    setSearch("");
    setFile("");
    setError("");
    setOTP("");
    setLeader("");
    setAdmin("");
    setSubSource("");
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const toFind: any = {
        rowsPerPage,
        page,
        qstatus,
        qLeader,
        sOption,
        search,
      };

      const response = await getB2APartners(toFind);
      if (response) {
        const value = response?.data?.data;
        setTotalCount(value?.totalPartners);
        setLeaders(value?.leaders);
        setAdmins(value?.admins);
        setPartners(value?.partners);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, trigger, qLeader, qstatus]);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !name?.trim() ||
      !email?.trim() ||
      !ccod?.trim() ||
      !state?.trim() ||
      !mobile?.trim() ||
      !location?.trim() ||
      !leader?.trim() ||
      !admin?.trim() ||
      !subSource?.trim()
    ) {
      alert("All fields are mandatory");
      return;
    } else {
      setLoading(true);
      setOTP("");
      setError("");

      const response = await adminOtp({
        admin,
        email,
        ccod,
        mobile,
        subSource,
      });

      if (response?.status === 200) {
        startTimer(time);
        setEmailList(response?.data?.data?.emails);
        setCreate(false);
        setShowOtp(true);
      } else if (response) {
        console.log(response);
        setError(response);
      }
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    setLoading(true);
    setOTP("");
    const response = await adminOtp({ admin, email, ccod, mobile, subSource });

    startTimer(time);
    if (response?.status === 200) {
      setEmailList(response?.data?.data?.emails);
      setCreate(false);
      setShowOtp(true);
    } else if (response?.status === 420) {
      alert(response?.data.message);
    }
    setLoading(false);
  };

  const verifyOTP = async () => {
    setError("");

    if (!otp || otp.length !== 6) {
      setError("Invalid OTP");

      return;
    } else if (file) {
      setLoading(true);

      const data = new FormData();
      data.append("file", file);
      data.append("e_otp", otp);
      data.append("admin", admin);
      const response = await uploadPartners(data);

      if (response?.status === 200) {
        alert("OTP Verified");
        setShowOtp(false);
        setTrigger(!trigger);
        empty();
      } else if (response?.status === 420) {
        alert(response?.data.message);
        setFile("");
      }
    } else {
      setLoading(true);
      setError("");

      const response = await verifyOTPandCreatePartner({
        name: name.trim(),
        email: email.trim(),
        ccod: ccod.trim(),
        state: state.trim(),
        mobile: mobile.trim(),
        location: location.trim(),
        subSource: subSource.trim(),
        otp: otp,
        admin,
        leader,
      });
      if (response?.status === 200) {
        alert("OTP Verified");
        setShowOtp(false);
        setTrigger(!trigger);
        empty();
      } else if (typeof response === "string") {
        // alert(response);
        setError("Invalid OTP");
      }
    }
    setLoading(false);
  };

  const getAdminApproval = async () => {
    console.log(file);
    if (!file) {
      alert("Please choose a file");
      return;
    } else if (!admin) {
      alert("Please choose admin for otp");
      return;
    } else {
      setLoading(true);
      setError("");
      const data = new FormData();
      data.append("file", file);
      data.append("admin", admin);
      const response = await adminOTPBluk(data);

      startTimer(time);
      if (response?.status === 200) {
        setEmailList(response?.data?.data?.emails);
        setBulk(false);
        setBulkInfo(false);
        setShowOtp(true);
      } else if (response) {
        // alert(response?.data.message);
        setError(response);
      }
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    const csvData = [
      {
        "Partner Full Name": "sample name",
        "Email Id": "sample@email.com",
        "Country Code": "+91",
        "Mobile Number": "99999999999",
        Location: "sample",
        State: "sample",
        "Team Leader Email": "sample@email.com",
        "Sub Source": "sample sub source",
      },
    ];
    const header =
      "Partner Full Name,Email Id,Country Code,Mobile Number,Location,State,Team Leader Email,Sub Source\n";
    const rows = csvData
      .map(
        (obj) =>
          `${obj["Partner Full Name"]},${obj["Email Id"]},${obj["Country Code"]},${obj["Mobile Number"]},${obj["Location"]},${obj["State"]},${obj["Team Leader Email"]},${obj["Sub Source"]}`
      )
      .join("\n");
    const csvContent = header + rows;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <SideBarB2A
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          {loading ? (
            <Loader />
          ) : (
            <>
              {bulk && (
                <div className="popupModal">
                  <div className="popupModal_main_2">
                    <div className="popupModal_header">
                      <span>
                        Upload Partner{" "}
                        <Tooltip
                          title="Click for more information"
                          onClick={() => {
                            setBulkInfo(true);
                            setFile("");
                          }}
                        >
                          <img
                            src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                            alt="profile"
                            className="ml-1"
                          />
                        </Tooltip>{" "}
                      </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBulk(false);
                          setFile("");
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <div className="popupModal_form_2">
                        <div className="popupModal_fields-2">
                          <span className="popupModal_label">
                            Select Admin For OTP <span className="red">*</span>
                          </span>

                          <select
                            className="popupModal_input "
                            value={admin}
                            onChange={(e) => {
                              setAdmin(e.target.value);
                              const adminE = admins.find((elem) => {
                                return elem.aid === e.target.value;
                              });

                              setAdminE(adminE?.p_email || "");
                            }}
                            required={true}
                          >
                            <option value="">Select Admin</option>
                            {admins &&
                              admins.map((elem, i) => {
                                return (
                                  <option value={elem.aid} key={i}>
                                    {elem.p_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="popupModal_fields-2">
                          <input
                            type="file"
                            className="popupModal_input fileInput"
                            onChange={handleFile}
                            accept=".csv"
                          />
                        </div>

                        <div
                          className="noteCreate"
                          style={{ color: "#E56874" }}
                        >
                          {error}
                        </div>

                        <div className="popupModal_fields-2">
                          <button
                            className="uploadPartners"
                            onClick={() => {
                              getAdminApproval();
                            }}
                          >
                            Upload Multiple Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {bulkInfo && (
                <div className="popupModal">
                  <div className="popupModal_main">
                    <div className="popupModal_header">
                      <span>
                        Expected format of the Partners File to be uploaded
                      </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBulkInfo(false);
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <div className="infoBlock">
                        <div className="infoRow">
                          <span>
                            1. The File has to be in the CSV format only.
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>

                        <div className="infoRow">
                          <span>2. Download the sample file from here.</span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              downloadCSV();
                              alert("Downloaded");
                            }}
                          >
                            Download Sample
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            3. An entry of any user may have the following
                            columns:
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>
                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; a. 'Partner Full Name'
                            (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "Partner Full Name"
                              );

                              alert("Copied Partner Full Name");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; b. 'Email Id' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Email Id");

                              alert("Copied Email Id");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; c. 'Country Code' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Country Code");

                              alert("Copied Country Code");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; d. 'Mobile Number' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Mobile Number");

                              alert("Copied Mobile Number");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; e. 'State' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("State");

                              alert("Copied State");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; f. 'Location' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Location");

                              alert("Copied Location");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; g. 'Team Leader Email'
                            (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "Team Leader Email"
                              );

                              alert("Copied Team Leader Email");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; h. 'Sub Source' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Sub Source");

                              alert("Copied Sub Source");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            4. If the file is not in the proper format, a proper
                            import request will not be generated.
                          </span>
                        </div>

                        <div className="infoRow">
                          <span>
                            5. The maximum numbers of Partners in 1 request is
                            limited to 50.
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>

                        <div className="infoRow">
                          <span>
                            6. Multiple Partners can be generated by uploading
                            the CSV file in the provided format and clicking the
                            "Upload Multiple Requests" button.
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>
                      </div>

                      <div className="popupModal_foot">
                        <button
                          onClick={() => {
                            setBulkInfo(false);
                          }}
                          className="pm_create"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {create && (
                <div className="popupModal">
                  <div className="popupModal_main">
                    <div className="popupModal_header">
                      <span>Create Consultant </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCreate(false);
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <form onSubmit={submitHandler}>
                        <div className="popupModal_form">
                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Partner Full Name <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={true}
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Enter Email ID <span className="red">*</span>
                            </span>
                            <input
                              type="email"
                              className="popupModal_input"
                              required={true}
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>

                          <div className="popupModal_fields popupModal_ccod_wrapper">
                            <span className="popupModal_label">
                              Phone Number <span className="red">*</span>
                            </span>
                            <div
                              className="popupModal_ccod"
                              style={{ background: "#fff" }}
                            >
                              <select
                                className="popupModal_input ccod_input"
                                value={ccod}
                                onChange={(e) => {
                                  setCCOD(e.target.value);
                                }}
                                required={true}
                              >
                                <option value="">Country</option>
                                {CountryCodes &&
                                  CountryCodes.map((elem, i) => {
                                    return (
                                      <option key={i} value={elem.value}>
                                        {elem.display}
                                      </option>
                                    );
                                  })}
                              </select>

                              <input
                                type="number"
                                required={true}
                                value={mobile}
                                maxLength={10}
                                minLength={10}
                                className="popupModal_input mob_input"
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              State <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              value={state}
                              required={true}
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            >
                              <option value="">Select State</option>
                              {LEADSQUARE_STATE_LOCATIONS &&
                                LEADSQUARE_STATE_LOCATIONS.map((elem, i) => {
                                  return (
                                    <option value={elem} key={i}>
                                      {elem}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Location <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              value={location}
                              onChange={(e) => {
                                setLocation(e.target.value);
                              }}
                              required={true}
                            >
                              <option value="">Select Location</option>
                              {LEADSQUARE_CENTER_LOCATIONS &&
                                LEADSQUARE_CENTER_LOCATIONS.map((elem, i) => {
                                  return (
                                    <option value={elem} key={i}>
                                      {elem}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Lead Sub Source <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={true}
                              value={subSource}
                              onChange={(e) => {
                                setSubSource(e.target.value);
                              }}
                            />
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Partner Type <span className="red">*</span>
                            </span>
                            <div className="radio-group">
                              <label className="custom-radio">
                                <input
                                  type="radio"
                                  name="option"
                                  defaultChecked
                                  disabled
                                />
                                <span className="radio-btn"></span>
                                B2A
                              </label>
                            </div>{" "}
                          </div>
                          <div className="popupModal_fields"></div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Team Leader <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              value={leader}
                              onChange={(e) => {
                                setLeader(e.target.value);
                              }}
                              required={true}
                            >
                              <option value="">Select Leader</option>
                              {leaders &&
                                leaders.map((elem, i) => {
                                  return (
                                    <option value={elem.aid} key={i}>
                                      {elem.p_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Select Admin For OTP{" "}
                              <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              value={admin}
                              onChange={(e) => {
                                setAdmin(e.target.value);
                                const adminE = admins.find((elem) => {
                                  return elem.aid === e.target.value;
                                });

                                setAdminE(adminE?.p_email || "");
                              }}
                              required={true}
                            >
                              <option value="">Select Admin</option>
                              {admins &&
                                admins.map((elem, i) => {
                                  return (
                                    <option value={elem.aid} key={i}>
                                      {elem.p_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="popupModal_fields"></div>
                        </div>
                        <div className="noteCreate">
                          Note: Selected Team Leader will be marked as lead
                          owner in LS for all the leads added by this Partner
                        </div>

                        <div
                          className="noteCreate"
                          style={{ color: "#E56874" }}
                        >
                          {error}
                        </div>

                        <div className="popupModal_foot">
                          <button className="pm_create" type="submit">
                            Create
                          </button>
                          <button
                            className="pm_create pm_cancel"
                            onClick={() => {
                              setCreate(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              {showOtp && (
                <div className="popupModal ">
                  <div className="popupModal_main " style={{ width: "350px" }}>
                    <div className="popupModal_header">
                      <span></span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowOtp(false);
                          empty();
                        }}
                      />
                    </div>
                    <div className="popupModal_body center-align">
                      <img
                        src="https://cdn.pegasus.imarticus.org/b2b/OTP.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="mt-1">OTP is valid upto 30 min</span>
                      <input
                        type="number"
                        className="popupModal_input w-50 mt-1 text-center"
                        required={true}
                        value={otp}
                        placeholder="0-0-0-0-0-0"
                        onChange={(e) => {
                          setOTP(e.target.value);
                        }}
                      />

                      <div className="footOTP" style={{ marginTop: "2rem" }}>
                        {seconds && seconds > 0 ? (
                          <div className="timer">
                            Resend in: {Math.floor(seconds / 60)}:
                            {10 > seconds - Math.floor(seconds / 60) * 60 &&
                              `0`}
                            {seconds - Math.floor(seconds / 60) * 60}
                          </div>
                        ) : (
                          <div className="timer">
                            <button
                              className="resendOtpBtn"
                              onClick={() => {
                                setSeconds(time);
                                resendOtp();
                              }}
                            >
                              Resend OTP
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="footOTP" style={{ color: "#E56874" }}>
                        {error}
                      </div>

                      <div className="footOTP ">
                        OTP has been sent on email: <br />
                        {adminE}
                        {emailList &&
                          emailList.length > 0 &&
                          emailList.map((elem, i) => {
                            return <div key={i}>{elem}</div>;
                          })}
                      </div>

                      <button
                        onClick={() => {
                          verifyOTP();
                        }}
                        className="pm_create w-50 mt-1 mb-3"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="partner_leads_header">
                <span>All Consultant </span>

                <div>
                  {checkDeepVisibility({
                    permissions: permissions,
                    resource: 15,
                    value: 2,
                  }) && (
                    <button
                      onClick={() => {
                        empty();
                        setBulk(true);
                      }}
                      className="back_btn col-green-reverse"
                      style={{ marginRight: "1rem" }}
                    >
                      Bulk Upload Consultant
                    </button>
                  )}

                  <button
                    onClick={() => {
                      empty();
                      setCreate(true);
                    }}
                    className="back_btn col-green"
                  >
                    + Create Consultant
                  </button>
                </div>
              </div>
              <hr />

              <div className="search_newapp mt-1">
                <div className="flex">
                  <div>
                    <select
                      name="cars"
                      id="cars"
                      value={sOption}
                      className="search_filters"
                      onChange={(e) => {
                        setsOption(e.target.value);
                      }}
                    >
                      <option value="">Select Search Via</option>
                      <option value="UID">Search Via UID</option>
                      <option value="EMAIL">Search Via Email ID</option>
                      <option value="MOBILE">Search Via Mobile Number</option>
                    </select>
                  </div>
                  <div>
                    {sOption && (
                      <input
                        type="text"
                        className="search_filters ml-1 ph-1"
                        placeholder={`Enter ${sOption}`}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    )}

                    {search && search.trim() && (
                      <button
                        className="form_back ml-1"
                        onClick={() => {
                          setTrigger(!trigger);
                        }}
                      >
                        Search
                      </button>
                    )}

                    {search && search.trim() && (
                      <button
                        className="form_back ml-1"
                        onClick={() => {
                          setSearch("");
                          setqLeader("");
                          setsOption("");
                          setQstatus("");
                          setTrigger(!trigger);
                        }}
                      >
                        Reset
                      </button>
                    )}
                  </div>
                </div>
                <div className="filters-2">
                  {checkDeepVisibility({
                    permissions: permissions,
                    resource: 15,
                    value: 2,
                  }) && (
                    <div className="ml-1">
                      <div className="filter_headers">Team Leader </div>
                      <select
                        onChange={(e) => {
                          setqLeader(e.target.value);
                        }}
                        value={qLeader}
                        className="filter_inputs"
                      >
                        <option value="">All</option>
                        {leaders &&
                          leaders.map((elem, i) => {
                            return (
                              <option value={elem.aid} key={i}>
                                {elem.p_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}

                  <div className="ml-1">
                    <div className="filter_headers">Partner Status</div>
                    <select
                      onChange={(e) => setQstatus(e.target.value)}
                      value={qstatus}
                      className="filter_inputs"
                    >
                      <option value="">All</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>

              {partners && partners.length > 0 ? (
                <div className="" style={{ width: "100%", marginTop: "2rem" }}>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "0.1rem" }}
                  >
                    <Table>
                      <TableHead className="table_head">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          S.No
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          UID
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Consultant Name{" "}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Consultant Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Consultant Mobile No.
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Team Leader
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Sub Source
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Created On
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Action
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {partners &&
                          partners.map((elem, i) => {
                            let viewLink = "";
                            viewLink += "?id=" + elem._id + "&aid=" + elem.aid;

                            if (elem?.details?.lead_source) {
                              viewLink +=
                                "&lsource=" + elem.details?.lead_source;
                            } else if (elem?.parent_email) {
                              viewLink += "&email=" + elem?.parent_email;
                            } else {
                              viewLink += "&email=" + elem?.p_email;
                            }
                            return (
                              <TableRow className="table_row" key={i}>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.p_uid}{" "}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.p_name}{" "}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_mobile}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.parent_email ? elem.parent_email : "-"}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.details?.subSource
                                    ? elem.details?.subSource
                                    : "-"}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {getDate(elem.createdAt)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                  className={
                                    elem.details.status === 1
                                      ? "trb approve_green"
                                      : "trb flagged_red"
                                  }
                                >
                                  {partnerStatus[elem.details.status]}{" "}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  <Link to={`/track_b2_leads` + viewLink}>
                                    View Profile
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">
                    Click on Create Partner to map the existing leads with a
                    partner
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageB2APartners;
