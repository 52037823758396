import { useState } from "react";
import { loginWithEmailOTP, setLoginCookies, verifyOTP } from "../../api/auth";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { emailLoginErrorMap } from "../../constants/constants";
import { nameSetter } from "../../redux/reducers/profile";
import { useDispatch } from "react-redux";

interface LoginWithOTPProps {
  setMethod: React.Dispatch<React.SetStateAction<string>>;
  loginMethods: {
    FORGOT: string;
    EMAIL: string;
  };
}

const LoginWithOTP: React.FC<LoginWithOTPProps> = ({
  loginMethods,
  setMethod,
}: LoginWithOTPProps) => {
  const time = 300;
  const [email, setEmail] = useState<string>("");
  const [next, setNext] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState<number>(time);
  const [otp, setOTP] = useState<string>("");
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [aid, setAid] = useState<string>("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const checkEmail = localStorage.getItem("partner_email");
  //   if (checkEmail) {
  //     setEmail(checkEmail);
  //   }
  // }, []);

  const startTimer = (initialSeconds: number) => {
    if (initialSeconds > 0) {
      setTimeout(() => {
        setSeconds(initialSeconds - 1);
        startTimer(initialSeconds - 1); // Call startTimer recursively
      }, 1000);
    }
  };

  const resendOtp = async () => {
    setLoader(true);
    if (!email.trim()) {
      alert("Enter Email");
      setLoader(false);
      return;
    } else {
      localStorage.setItem("partner_email", email.trim());
      const response = await loginWithEmailOTP({ email });
      if (response?.status === 200) {
        setAid(response?.data?.data?.aid);
        setNext(true);

        startTimer(time);
        setLoader(false);
      } else {
        alert("Something went wrong please login via password");
        setLoader(false);
      }
    }
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    if (!email.trim()) {
      alert("Enter Email");
      setLoader(false);
      return;
    } else {
      const response = await loginWithEmailOTP({ email });
      if (response?.status === 200) {
        setAid(response?.data?.data?.aid);
        setNext(true);
        startTimer(time);
        setLoader(false);
      } else {
        alert("Something went wrong please login via password");
        setLoader(false);
      }
    }
  };

  const otpHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    if (!otp.trim()) {
      alert("Enter OTP");
      setLoader(false);
      return;
    } else {
      const response = await verifyOTP({ otp, aid });

      if (response) {
        if (response.status === 200) {
          const data = response.data.data;
          setLoginCookies({
            at: data.at,
            aid: data.aid,
            secret: data.secret,
            rt: data.rt,
            isLoggedIn: true,
            pid: data.pid,
            sid: data.sid,
            user: data.user,
            profile: data.Profile[0],
          });
          dispatch(nameSetter(response.data.data.Profile[0].nam));
          setLoader(false);
          navigate("/dashboard");
        } else {
          setLoader(false);
          setError(emailLoginErrorMap.W_OTP);
        }
      } else {
        setError(emailLoginErrorMap.W_OTP);
        setLoader(false);
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div>
        <div className="login_form_header">Login with OTP</div>
        <div className="login_form_des">
          Before moving forward, kindly login!
        </div>

        <div
          className="login_form_des"
          style={{ cursor: "pointer", fontSize: "16px" }}
          onClick={() => setMethod(loginMethods.EMAIL)}
        >
          {" "}
          <img
            src="https://cdn.pegasus.imarticus.org/certificate-controller/backButton.svg"
            alt="eye2"
            className="show-eye"
            style={{ width: "12px" }}
          ></img>{" "}
          &nbsp; Back
        </div>

        {error && <div className="login_error">{error}</div>}

        {!next ? (
          <form onSubmit={submitHandler}>
            <div className="login_labels">Enter Email Id</div>

            <input
              type="text"
              className="login_inputs"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              disabled={next}
            />

            <button
              className="login_btn"
              type="submit"
              disabled={next}
              style={{ width: "100%" }}
            >
              Send OTP
            </button>
          </form>
        ) : (
          <form onSubmit={otpHandler}>
            <div className="login_labels">Enter Email Id</div>

            <input
              type="text"
              className="login_inputs"
              value={email}
              disabled={next}
            />

            <div className="login_labels">Enter OTP</div>
            {seconds && seconds > 0 ? (
              <div className="timer">
                Resend in: {Math.floor(seconds / 60)}:
                {10 > seconds - Math.floor(seconds / 60) * 60 && `0`}
                {seconds - Math.floor(seconds / 60) * 60}
              </div>
            ) : (
              <div className="timer">
                <button
                  className="resendOtpBtn"
                  onClick={() => {
                    setSeconds(time);
                    resendOtp();
                  }}
                >
                  Resend OTP
                </button>
              </div>
            )}

            <input
              type="number"
              className="login_inputs"
              value={otp}
              onClick={() => {
                setError("");
              }}
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              disabled={!next}
            />

            <button
              className="login_btn"
              type="submit"
              style={{ width: "100%" }}
            >
              Login
            </button>
          </form>
        )}

        <div className="login_switch">
          <div
            className="login_switch_btn"
            onClick={() => setMethod(loginMethods.EMAIL)}
          >
            Login with Email
          </div>
        </div>
        <hr />
        <div className="login_adm_btn">
          <button
            className="login_switch_adm_btn"
            onClick={() => {
              window.open(
                "https://imarticus.org/imarticus-edtech-entrepreneur/"
              );
            }}
          >
            Not a Partner?
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginWithOTP;
