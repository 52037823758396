const Loader: React.FC = () => {
  return (
    <div className={`visible_loader`}>
      <div className={`loaderHeight`}>
        <div
          className={`rotate360`}
          style={{
            background:
              "url(https://cdn.pegasus.imarticus.org/images/Imarticus_Loader_Circle-02-1.svg) 49.72% no-repeat",
            width: "80px",
            margin: "auto",
            height: "80px",
          }}
        />
        <div
          style={{
            height: "50px",
            width: "50px",
            position: "absolute",
            margin: "auto",
            background:
              "url(https://cdn.pegasus.imarticus.org/imarticus_2/imarticus_logo_loader_new.svg) center no-repeat",
            backgroundSize: "auto",
            left: "calc(50% - 25px)",

            top: "18px",
          }}
        ></div>
      </div>
    </div>
  );
};
export default Loader;
