import { sidebarMap } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import { checkVisibility } from "../utility/helper";

interface SideBarProps {
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
  permissions: Record<number, number[]> | undefined;
}

const SideBarB2A: React.FC<SideBarProps> = ({
  active,
  setActive,
  permissions,
}: SideBarProps) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <img
        src="https://cdn.pegasus.imarticus.org/labs/sidebar-logo.svg"
        alt="logo"
        className="sidelogo"
      />

      <div className="sidebar_list">
        {checkVisibility({ permissions: permissions, resource: 17 }) && (
          <div
            onClick={() => {
              navigate("/master");
            }}
          >
            <img
              src="https://cdn.pegasus.imarticus.org/Aap-landing-page/images/arrow.svg"
              alt="logo"
              style={{ width: "10px" }}
            />
            <span className="ml-1">Back</span>
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 15 }) && (
          <div
            className={
              active === sidebarMap.B2A_MANAGE_PARTNER ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.B2A_MANAGE_PARTNER);
              navigate("/m_b2a_partner");
            }}
          >
            Manage Consultant
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 16 }) && (
          <div
            className={
              active === sidebarMap.B2A_REVIEW_BULK ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.B2A_REVIEW_BULK);
              navigate("/r_bulk");
            }}
          >
            Review Bulk Leads
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBarB2A;
