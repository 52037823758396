import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApplicantSideApplication } from "../../api/partner";
import { Application, ApplicationInfos } from "../../types";
import { logout } from "../../api/auth";
import Cookies from "universal-cookie";
import { applicantSideStatusMap } from "../../constants/constants";
import Loader from "../../components/Loader";

interface AllPartnerSideApplicationProps {
  permissions: Record<number, number[]> | undefined;
}
let cookies = new Cookies();

const AllPartnerSideApplication: React.FC<AllPartnerSideApplicationProps> = ({
  permissions,
}: AllPartnerSideApplicationProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<Application | null>(null);
  const [drop, setDrop] = useState(false);

  const [applicationInfo, setApplicationinfo] =
    useState<ApplicationInfos | null>(null);

  useEffect(() => {
    setLoading(true);
    const applicationLookup = async () => {
      const response = await getApplicantSideApplication();
      if (response?.status === 200) {
        let blocks = response?.data?.data;
        setApplication(blocks.application);
        setApplicationinfo(blocks.applicationInfo);
        // if (
        //   blocks?.applicationInfo?.application_stage === 5 ||
        //   blocks?.applicationInfo?.application_stage === 6
        // ) {
        //   setResubmit(true);
        // }

        // if (
        //   blocks?.applicationInfo?.application_stage === 2 ||
        //   blocks?.applicationInfo?.application_stage === 1
        // ) {
        //   setResume(true);
        // }

        if (
          blocks?.applicationInfo?.application_stage === 10 ||
          blocks?.applicationInfo?.application_stage === 11
        ) {
          navigate("/track_o_leads");
        }
      }
      setLoading(false);
    };
    applicationLookup();
  }, [navigate]);

  let progress = 0;

  if (applicationInfo) {
    if (
      applicationInfo.application_stage === 9 ||
      applicationInfo.application_stage === 10 ||
      applicationInfo.application_stage === 3 ||
      applicationInfo.application_stage === 5 ||
      applicationInfo.application_stage === 6
    ) {
      progress = 50;
    }
  }

  return (
    <div className="v_application">
      {loading || !application ? (
        <Loader />
      ) : (
        <>
          {/* TopBar */}
          <div className="v_application_topbar">
            <img
              src="https://cdn.pegasus.imarticus.org/partnerimarticus/logo1.svg"
              alt="logo"
            />
            <div
              className="topbar_profile"
              onMouseEnter={() => {
                setDrop(true);
              }}
              onMouseLeave={() => {
                setDrop(false);
              }}
            >
              <img
                src="https://cdn.pegasus.imarticus.org/images/Profile-01.svg"
                alt="profile"
                className="topbar_pic"
              />
              <span>{cookies.get("Profile").nam || "USER"}</span>
              <img
                src="https://cdn.pegasus.imarticus.org/labs/down-arrow.svg"
                alt="arrow"
              />
            </div>
            {drop && (
              <div
                className="topbar_drop2"
                onMouseEnter={() => {
                  setDrop(true);
                }}
                onMouseLeave={() => {
                  setDrop(false);
                }}
              >
                <div
                  onClick={() => {
                    navigate("/a_app");
                  }}
                >
                  My Applications
                </div>
                <div
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                >
                  Logout
                </div>
              </div>
            )}
          </div>

          <div className="v_application_main">
            {/* <div
              className="v_a_back"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </div> */}

            <div className="v_a_header">
              <div className="v_a_header2">{application?.name}</div>
              <div className="v_a_infos">
                <span className="v_a_status">Status: </span>
                <span className="v_a_status v_a_status_green">
                  {applicationInfo &&
                    applicantSideStatusMap[applicationInfo.application_stage]}
                </span>
              </div>

              <div className="status_bar">
                <progress
                  className="progress_bar"
                  value={progress}
                  max="100"
                ></progress>
                <div className="progress_bar_checkpoint">
                  <div className="pointer">
                    <div className="point">
                      <div className="inner-dot"></div>
                    </div>
                    <div className="pointer_sub_head">
                      Application Submitted
                    </div>
                  </div>
                  <div className="pointer">
                    <div className="point">
                      {applicationInfo?.application_stage === 5 ||
                      applicationInfo?.application_stage === 6 ? (
                        <div className="inner-dot-2"></div>
                      ) : (
                        <div className="inner-dot"></div>
                      )}
                    </div>
                    <div className="pointer_sub_head">
                      {applicationInfo?.application_stage === 5 ||
                      applicationInfo?.application_stage === 6 ? (
                        <div style={{ textAlign: "center" }}>
                          Your Application has been Flagged <br />
                          There are some issue with your application. Kindly{" "}
                          <br /> check your application and correct your details
                        </div>
                      ) : (
                        "Application Under Review"
                      )}
                    </div>
                  </div>
                  <div className="pointer">
                    <div className="point">
                      <div className="inner-dot"></div>
                    </div>
                    <div className="pointer_sub_head">Application Accepted</div>
                  </div>
                </div>

                <div>
                  {(applicationInfo?.application_stage === 5 ||
                    applicationInfo?.application_stage === 6) && (
                    <div className="resubmitApplication">
                      <button
                        className="reapp"
                        onClick={() => {
                          navigate("/fill_application");
                        }}
                      >
                        {" "}
                        Resubmit Application
                      </button>
                    </div>
                  )}

                  {applicationInfo?.application_stage === 2 && (
                    <div className="resubmitApplication">
                      <button
                        className="reapp"
                        onClick={() => {
                          navigate("/fill_application");
                        }}
                      >
                        {" "}
                        Resume Application
                      </button>
                    </div>
                  )}

                  {applicationInfo?.application_stage === 1 && (
                    <div className="resubmitApplication">
                      <button
                        className="reapp"
                        onClick={() => {
                          navigate("/fill_application");
                        }}
                      >
                        {" "}
                        Start Application
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AllPartnerSideApplication;
