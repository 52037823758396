const NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : "";

export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV
  ? process.env.REACT_APP_BUILD_ENV
  : "local";

//localhost-------------
let _routes = {
  BASE_URL: "http://localhost:3000",
  WEBSITE_LINK: "http://localhost:3026",
};

let _CONFIGS = {
  DOMAIN: "localhost",
  PATH: "/",
};

if (NODE_ENV === "production" && BUILD_ENV === "testing") {
  //testing------------
  _routes = {
    BASE_URL: "https://apiht.pegasust.imarticus.org",
    WEBSITE_LINK: "http://partnert.imarticus.org",
  };

  _CONFIGS = {
    DOMAIN: ".imarticus.org",
    PATH: "/",
  };
} else if (NODE_ENV === "production" && BUILD_ENV === "staging") {
  //staging------------
  _routes = {
    BASE_URL: "https://apihs.pegasuss.imarticus.org",
    WEBSITE_LINK: "http://partners.imarticus.org",
  };
  _CONFIGS = {
    DOMAIN: ".imarticus.org",
    PATH: "/",
  };
} else if (NODE_ENV === "production" && BUILD_ENV === "production") {
  //production------------
  _routes = {
    BASE_URL: "https://apih.pegasus.imarticus.org",
    WEBSITE_LINK: "http://partner.imarticus.org",
  };
  _CONFIGS = {
    DOMAIN: ".imarticus.org",
    PATH: "/",
  };
}

export const routes = _routes;
export const config = _CONFIGS;
