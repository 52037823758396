import { useNavigate } from "react-router-dom";

const SideBar4: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <img
        src="https://cdn.pegasus.imarticus.org/labs/sidebar-logo.svg"
        alt="logo"
        className="sidelogo"
      />

      <div className="sidebar_list">
        <div
          onClick={() => {
            navigate("/master");
          }}
        >
          <img
            src="https://cdn.pegasus.imarticus.org/Aap-landing-page/images/arrow.svg"
            alt="logo"
            style={{ width: "10px" }}
          />
          <span className="ml-1">Back</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar4;
