import { sidebarMap } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import { checkVisibility } from "../utility/helper";

interface SideBarProps {
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
  permissions: Record<number, number[]> | undefined;
}

const SideBar2: React.FC<SideBarProps> = ({
  active,
  setActive,
  permissions,
}: SideBarProps) => {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  let lsource = params.get("lsource") || undefined;
  let email = params.get("email") || undefined;
  let id = params.get("id") || undefined;
  let aid = params.get("aid") || undefined;

  let viewLink = "";

  if (id) {
    viewLink += "?id=" + id;
  }
  if (aid) {
    viewLink += "&aid=" + aid;
  }
  if (lsource) {
    viewLink += "&lsource=" + lsource;
  } else if (email) {
    viewLink += "&email=" + email;
  }

  return (
    <div className="sidebar">
      <img
        src="https://cdn.pegasus.imarticus.org/labs/sidebar-logo.svg"
        alt="logo"
        className="sidelogo"
      />
      <div className="sidebar_list">
        <div
          onClick={() => {
            navigate("/m_partner");
          }}
        >
          <img
            src="https://cdn.pegasus.imarticus.org/Aap-landing-page/images/arrow.svg"
            alt="logo"
            style={{ width: "10px" }}
          />
          <span className="ml-1">Back</span>
        </div>

        {checkVisibility({ permissions: permissions, resource: 2 }) && (
          <div
            className={
              active === sidebarMap.TRACK_LEADS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.TRACK_LEADS);
              navigate("/trackLeads" + viewLink);
            }}
          >
            Track Leads
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 2 }) && (
          <div
            className={
              active === sidebarMap.PARTNER_PROFILE ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.PARTNER_PROFILE);
              navigate("/partnerProfile" + viewLink);
            }}
          >
            View Profile
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar2;
