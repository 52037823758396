import { useState } from "react";
import { createSG } from "../../api/partner";

import {
  LEADSQUARE_CENTER_LOCATIONS,
  LEADSQUARE_STATE_LOCATIONS,
} from "../../utility/utils";
import Loader from "../Loader";

interface CreateSalesGroupProps {
  setCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateSalesGroup: React.FC<CreateSalesGroupProps> = ({
  setCreate,
}: CreateSalesGroupProps) => {
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [location, setLocation] = useState<string>("");
  const [state, setState] = useState<string>("");

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let fName = name.trim();

    if (fName && location && state) {
      const response = await createSG({ name, location, state });

      if (response?.status === 200) {
        setCreate(false);
      }

      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <button
              onClick={() => {
                setCreate(false);
              }}
              className="back_btn"
            >
              Back
            </button>
          </div>
          <form onSubmit={submitHandler} className="fillup_form sg_types">
            <div className="partner_leads_header">Create Sales Group</div>
            <hr style={{ width: "20%" }} />
            <div className="fillup_form_field">
              <div>Name</div>
              <input
                type="text"
                className="fillup_form_inputs w-100"
                placeholder="Type Name Here..."
                value={name}
                required={true}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="fillup_form_field">
              <div>Location</div>
              <select
                className="fillup_form_inputs w-95"
                required={true}
                style={{ background: "white" }}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              >
                <option value="">Select Location</option>
                {LEADSQUARE_CENTER_LOCATIONS &&
                  LEADSQUARE_CENTER_LOCATIONS.map((elem, i) => {
                    return (
                      <option value={elem} key={i}>
                        {elem}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="fillup_form_field ">
              <div>State</div>
              <select
                className="fillup_form_inputs w-95"
                required={true}
                style={{ background: "white" }}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="">Select State</option>
                {LEADSQUARE_STATE_LOCATIONS &&
                  LEADSQUARE_STATE_LOCATIONS.map((elem, i) => {
                    return (
                      <option value={elem} key={i}>
                        {elem}
                      </option>
                    );
                  })}
              </select>
            </div>

            <button type="submit" className="login_btn w30">
              Submit
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default CreateSalesGroup;
