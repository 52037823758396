import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import { sidebarMap } from "../../../constants/constants";

import {
  getApplicantSideApplication,
  getConsultantProfile,
  inactivePartner,
} from "../../../api/partner";
import { Application, BDetails } from "../../../types";
import Loader from "../../../components/Loader";

import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { RegistryWidgetsType } from "@rjsf/utils";
import { useNavigate } from "react-router-dom";
import SideBarB2A2 from "../../../components/SideBarB2A_2";

interface ConsultantProfileProps {
  permissions: Record<number, number[]> | undefined;
}

const ConsultantProfile: React.FC<ConsultantProfileProps> = ({
  permissions,
}: ConsultantProfileProps) => {
  const [active, setActive] = useState(sidebarMap.PARTNER_PROFILE);
  const [loading, setLoading] = useState<boolean>(false);
  const params = new URLSearchParams(window.location.search);
  let email = params.get("email") || undefined;
  let aid = params.get("aid") || "";

  const [details, setDetails] = useState<BDetails>();

  const [trigger, setTrigger] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleInactive = async () => {
    // add confirm
    if (
      window.confirm("Are you sure you want to mark this partner as inactive?")
    ) {
      setLoading(true);

      const response = await inactivePartner(aid);

      if (response?.status === 200) {
        alert("Partner Marked Inactive");
        setTrigger(!trigger);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getConsultantProfile(aid);
      if (response) {
        let blocks = response?.data?.data.detail;
        setDetails(blocks);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, aid, trigger]);

  return (
    <div>
      <SideBarB2A2
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="breadcrumb">
            <span
              className="track_lead_bread"
              onClick={() => {
                navigate("/m_partner");
              }}
            >
              Manage Consultants
            </span>
            <img
              src="https://cdn.pegasus.imarticus.org/pgBandF/ArrowBlack.svg"
              alt="profile"
              style={{ width: "4px" }}
              className="ml-1"
            />
            <span className="ml-1 partnerName">
              {details && details.p_name && details.p_name}
            </span>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div>
              {details && (
                <div className="consultantDetails">
                  <div className="cdHead">Basic Details</div>
                  <hr />

                  <div>
                    <div className="popupModal_form">
                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Full Name <span className="red">*</span>
                        </span>
                        <input
                          type="text"
                          className="popupModal_input"
                          value={details.p_name}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Email ID <span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.p_email}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Mobile Number<span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.p_mobile}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          State <span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.state}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Location<span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.location}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields"></div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-1"></div>
              {details && details.status === 1 ? (
                <div className="inact">
                  <button
                    className="inact_btn"
                    onClick={() => {
                      handleInactive();
                    }}
                  >
                    Mark as Inactive
                  </button>
                </div>
              ) : (
                <div className="inact"></div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultantProfile;
