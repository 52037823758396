import { useEffect, useState } from "react";
import LoginWithEmail from "../../components/Login/LoginWithEmail";
import LoginWithOTP from "../../components/Login/LoginWithOTP";
import ForgotPassword from "../../components/Login/ForgotPassword";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const loginMethods = {
  EMAIL: "email",
  OTP: "otp",
  FORGOT: "forgot",
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [method, setMethod] = useState(loginMethods.EMAIL);

  useEffect(() => {
    const cookies = new Cookies();
    let isLoggedIn = cookies.get("isLoggedIn");

    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <div className="login_main">
          <div className="login_container">
            <div className="login_img_container">
              <div className="login_img_container_head">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/logo1.svg"
                  alt="logo"
                />
                <div className="login_img_container_header">
                  Welcome to Partner portal
                </div>
                <div className="login_img_container_span">
                  The industry's most reputed award-winning education ecosystem
                </div>
              </div>
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/login.png"
                alt="logo"
                className="login_img"
              />
            </div>
            <div className="login_form">
              <div className="login_form_container">
                {method === loginMethods.EMAIL && (
                  <LoginWithEmail
                    setMethod={setMethod}
                    loginMethods={loginMethods}
                  />
                )}

                {method === loginMethods.OTP && (
                  <LoginWithOTP
                    setMethod={setMethod}
                    loginMethods={loginMethods}
                  />
                )}

                {method === loginMethods.FORGOT && (
                  <ForgotPassword
                    setMethod={setMethod}
                    loginMethods={loginMethods}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
