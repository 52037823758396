import { sidebarMap } from "../constants/constants";
import { useNavigate } from "react-router-dom";
import { checkVisibility } from "../utility/helper";

interface SideBarProps {
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
  permissions: Record<number, number[]> | undefined;
}

const SideBarB2AC: React.FC<SideBarProps> = ({
  active,
  setActive,
  permissions,
}: SideBarProps) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <img
        src="https://cdn.pegasus.imarticus.org/labs/sidebar-logo.svg"
        alt="logo"
        className="sidelogo"
      />

      <div className="sidebar_list">
        {checkVisibility({ permissions: permissions, resource: 18 }) && (
          <div
            className={
              active === sidebarMap.UPLOAD_LEADS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.UPLOAD_LEADS);
              navigate("/u_leads");
            }}
          >
            Upload Leads
          </div>
        )}

        {checkVisibility({ permissions: permissions, resource: 19 }) && (
          <div
            className={
              active === sidebarMap.TRACK_C_LEADS ? "sidebar_active" : ""
            }
            onClick={() => {
              setActive(sidebarMap.TRACK_C_LEADS);
              navigate("/track_c_leads");
            }}
          >
            Track Leads
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBarB2AC;
