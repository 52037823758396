import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllPartnerTypes } from "../../api/partner";
import { PartnerTypes } from "../../types";

import { getDate } from "../../utility/helper";
import Loader from "../Loader";

interface ViewAllTypesProps {
  setCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewAllTypes = ({ setCreate }: ViewAllTypesProps) => {
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [partnerTypes, setPartnerTypes] = useState<PartnerTypes[]>([]);

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getAllPartnerTypes({ rowsPerPage, page });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setTotalCount(value.totalTypes);
        setPartnerTypes(value.partnerTypes);
      }

      setLoading(false);
    };

    allTypes();
  }, [rowsPerPage, page]);

  return (
    <>
      <div className="partner_leads_header">
        <span>All Partner Types</span>
        <div>
          <button
            onClick={() => {
              setCreate(true);
            }}
            className="back_btn col-green"
          >
            + Create Types
          </button>
        </div>
      </div>
      <hr className="w30" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <TablePagination
              rowsPerPageOptions={[10, 50, 75, 250, 150]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <TableContainer
            component={Paper}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Table>
              <TableHead className="table_head">
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  {" "}
                  Name
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Form Used{" "}
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Requires Form
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Has Parent
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Requires OTP
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Requires Lead Source
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Website Default
                </TableCell>
                <TableCell
                  className="trb"
                  align="center"
                  style={{ minWidth: "100px" }}
                >
                  Date Created
                </TableCell>
              </TableHead>
              <TableBody>
                {partnerTypes.length > 0 &&
                  partnerTypes.map((elem, i) => {
                    return (
                      <TableRow key={i} className="table_row">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          {elem.name}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          {elem.form_name ? elem.form_name : "-"}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {elem.requires_application ? (
                            <img
                              alt="tick"
                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                            ></img>
                          ) : (
                            <img
                              alt="cross"
                              src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                            ></img>
                          )}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {elem.parent ? (
                            <img
                              alt="tick"
                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                            ></img>
                          ) : (
                            <img
                              alt="cross"
                              src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                            ></img>
                          )}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {elem.otp ? (
                            <img
                              alt="tick"
                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                            ></img>
                          ) : (
                            <img
                              alt="cross"
                              src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                            ></img>
                          )}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {elem.lead_source ? (
                            <img
                              alt="tick"
                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                            ></img>
                          ) : (
                            <img
                              alt="cross"
                              src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                            ></img>
                          )}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {elem.website_default ? (
                            <img
                              alt="tick"
                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                            ></img>
                          ) : (
                            <img
                              alt="cross"
                              src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                            ></img>
                          )}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          {getDate(elem.createdAt)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default ViewAllTypes;
