const SideBar3: React.FC = () => {
  return (
    <div className="sidebar">
      <img
        src="https://cdn.pegasus.imarticus.org/labs/sidebar-logo.svg"
        alt="logo"
        className="sidelogo"
      />

      <div className="sidebar_list"></div>
    </div>
  );
};

export default SideBar3;
