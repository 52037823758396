import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  name: string;
}

const initialState: CounterState = {
  name: "",
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    nameSetter: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
  },
});

export const { nameSetter } = ProfileSlice.actions;

export default ProfileSlice.reducer;
