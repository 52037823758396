import { useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import { pageOptions, sidebarMap } from "../../constants/constants";
import ViewApplication from "../../components/Application/ViewAllApplication";
import CreateApplication from "../../components/Application/CreateApplication";

interface ApplicationProps {
  permissions: Record<number, number[]> | undefined;
}

const Application: React.FC<ApplicationProps> = ({
  permissions,
}: ApplicationProps) => {
  const [active, setActive] = useState<string>(sidebarMap.APPLICATIONS);
  const [tab, setTab] = useState<string>(pageOptions.VIEW);

  return (
    <div>
      <SideBar
        permissions={permissions}
        setActive={setActive}
        active={active}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="partner_leads">
          {tab === pageOptions.VIEW && <ViewApplication setTab={setTab} />}
          {tab === pageOptions.CREATE && <CreateApplication setTab={setTab} />}
        </div>
      </div>
    </div>
  );
};

export default Application;
