import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllPartnerTypes, getApplications } from "../../api/partner";
import { ApplicationInfos, PartnerTypes, SalesGroup } from "../../types";
import { useNavigate } from "react-router-dom";
import { applicationStagetoNumber } from "../../constants/constants";
import Loader from "../Loader";

interface VerifiedApplicationProps {
  setSent: React.Dispatch<React.SetStateAction<number>>;
  setReview: React.Dispatch<React.SetStateAction<number>>;
}

const VerifiedApplication = ({
  setSent,
  setReview,
}: VerifiedApplicationProps) => {
  const [totalCount, setTotalCount] = useState(0); // Total number of rows
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<ApplicationInfos[]>([]);
  const navigate = useNavigate();
  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");

  const [qType, setQType] = useState<string>("");
  const [sOption, setsOption] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [partnerTypes, setPartnerTypes] = useState<PartnerTypes[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [qSG, setqSG] = useState<string>("");
  const [allSgs, setAllSgs] = useState<SalesGroup[]>([]);

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getApplications({
        rowsPerPage,
        page,
        status: [applicationStagetoNumber.PROCESS_FINISHED],

        qType,
        qSG,
        sDate,
        eDate,
        sOption,
        search,
      });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setTotalCount(value.totalapplications);
        setApplication(value.applications);
        setReview(value.allReview);
        setSent(value.allSent);
        setAllSgs(value.sgNames);
      }

      setLoading(false);
    };

    allTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, trigger, qType, eDate, qSG]);

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getAllPartnerTypes({ rowsPerPage: 0, page: 0 });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setPartnerTypes(value.partnerTypes);
      }
    };
    allTypes();
  }, []);

  return (
    <>
      <div className="filters ">
        <div>
          <div className="filter_headers">Partner Type</div>
          <select
            className="filter_inputs"
            value={qType}
            onChange={(e) => {
              setQType(e.target.value);
            }}
          >
            <option value="">All</option>

            {partnerTypes &&
              partnerTypes.map((elem, i) => {
                return <option value={elem._id}>{elem.name}</option>;
              })}
          </select>
        </div>

        <div>
          <div className="filter_headers">Sales Group</div>
          <select
            className="filter_inputs"
            value={qSG}
            onChange={(e) => {
              setqSG(e.target.value);
            }}
          >
            <option value="">All</option>

            {allSgs &&
              allSgs.length > 0 &&
              allSgs.map((elem, i) => {
                if (elem._id !== "*") {
                  return (
                    <option value={elem._id} key={i}>
                      {elem.name}
                    </option>
                  );
                } else {
                  return <></>;
                }
              })}
          </select>
        </div>

        <div>
          <div className="filter_headers">From Date</div>
          <input
            value={sDate}
            onChange={(e) => {
              setsDate(e.target.value);
            }}
            type="date"
            className="filter_inputs"
          />
        </div>
        <div>
          <div className="filter_headers">To Date</div>
          <input
            type="date"
            className="filter_inputs"
            disabled={sDate ? false : true}
            value={eDate}
            onChange={(e) => {
              seteDate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="application_header" style={{ marginTop: "1rem" }}>
        All Verified Applications{" "}
        <Tooltip title="In Verified Applications, Applications that are completely verified are shown.">
          <img
            src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
            alt="profile"
            className="ml-1"
          />
        </Tooltip>
      </div>
      <hr />

      <div className="search_newapp mt-1">
        <div className="flex">
          <div>
            <select
              name="cars"
              id="cars"
              className="search_filters"
              value={sOption}
              onChange={(e) => {
                setsOption(e.target.value);
              }}
            >
              <option value="">Select Search Via</option>
              <option value="UID">Search Via UID</option>
              <option value="EMAIL">Search Via Email ID</option>
              <option value="MOBILE">Search Via Mobile Number</option>
            </select>
          </div>
          <div>
            {sOption && (
              <input
                type="text"
                className="search_filters ml-1 ph-1"
                value={search}
                placeholder={`Enter ${sOption}`}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            )}

            {search && search.trim() && (
              <button
                className="form_back ml-1"
                onClick={() => {
                  setTrigger(!trigger);
                }}
              >
                Search
              </button>
            )}

            <button
              className="form_back ml-1"
              onClick={() => {
                setSearch("");
                setQType("");
                setsDate("");
                seteDate("");
                setsOption("");
                setqSG("");
                setTrigger(!trigger);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {application && application.length > 0 ? (
            <div className="">
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 150]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

              <TableContainer
                component={Paper}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Table>
                  <TableHead className="table_head ">
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      S. No.
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Application Form Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner UID
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Email ID
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Mobile Number
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Partner Type
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Sales Group
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Application Verified On
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Associate Partner
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Version
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Actions
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {application &&
                      application.map((elem, i) => {
                        return (
                          <TableRow className="table_row" key={i}>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {i + 1}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                              // className="link"
                              // onClick={() => {
                              //   navigate(
                              //     "/v_application?id=" + elem.application_id
                              //   );
                              // }}
                              >
                                {elem.p_a_name}
                              </span>
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_uid}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_name}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_email}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_mobile}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_type}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.sg && elem.sg.length > 0 ? (
                                <>
                                  {elem.sg.map((sg, i) => {
                                    return (
                                      <div key={i} className="sg_users">
                                        {sg}
                                        <br />
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem?.verified_rejected_date &&
                                new Date(
                                  elem?.verified_rejected_date
                                ).toLocaleDateString()}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              {elem.parent_email ? elem.parent_email : "-"}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              V_{elem.version}{" "}
                            </TableCell>

                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <div className="action-icons">
                                <img
                                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                  alt="logo"
                                  style={{ width: "30px" }}
                                  onClick={() => {
                                    navigate(
                                      "/r_application?id=" +
                                        elem.application_id +
                                        "&aid=" +
                                        elem.partner_aid
                                    );
                                  }}
                                />

                                <img
                                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/doc.svg"
                                  alt="logo"
                                  style={{ width: "30px" }}
                                  onClick={() => {
                                    window.open(elem.agreement);
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="no_application">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                alt="profile"
                className=""
              />
              <span className="na_head">It's empty here!</span>
              <span className="na_desc">Opps! Its empty here</span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default VerifiedApplication;
