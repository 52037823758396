import Axios from "axios";
import { ROUTES } from "../constants/routes";
import Cookies from "universal-cookie";
import { logout } from "./auth";
import { getPatnerLeadsProps } from "../types";
import axios from "axios";

let cookies = new Cookies();

Axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 498) {
      logout();
    }
    return Promise.reject(error);
  }
);

interface getPartnerPermissionProp {
  setPermissions: React.Dispatch<
    React.SetStateAction<Record<number, []> | undefined>
  >;
}

export const getPartnerPermission = async ({
  setPermissions,
}: getPartnerPermissionProp) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const query = "?aid=" + cookies.get("aid");

  try {
    const res = await Axios.get(ROUTES.partnerPermission + query, config);

    if (res) {
      if (res.status === 200) {
        let permissionMap = res.data?.data?.permissionMap;
        let perLen = Object.keys(permissionMap);

        if (perLen.length <= 0) {
          alert("You are not authorise to view this panel");
          logout();
        } else {
          setPermissions(res?.data?.data?.permissionMap);
          // setResources(res?.data?.data?.resources);
        }
      } else {
        alert(res?.data?.message);
        // logout();
      }
    } else {
      alert("Something went wrong");
      logout();
    }
  } catch (error) {
    console.log(error);
    alert("Not Allowed");
    logout();
  }
};

export const getPartnerLeads = async ({
  rowsPerPage,
  page,
  status,
  search,
  sDate,
  eDate,
  qLS,
  qSG,
  qType,
}: getPatnerLeadsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = {
    rowsPerPage,
    page,
    status,
    search,
    sDate,
    eDate,
    qLS,
    qSG,
    qType,
  };

  try {
    const res = await Axios.post(ROUTES.partnerLead, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

export const getFormBlocks = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = {};

  try {
    const res = await Axios.post(ROUTES.getFormBlocks, data, config);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
        return res;
      }
    } else {
      alert("Something went wrong");
      return res;
    }
  } catch (error) {
    alert("Something went Wrong");
    console.log(error);
  }
};

interface createApplicationProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  form: string[];
}

export const createApplication = async ({
  setLoading,
  name,
  form,
}: createApplicationProps) => {
  setLoading(true);
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let aid = cookies.get("aid");
  let data = { name, form, aid };

  try {
    const res = await Axios.post(ROUTES.createFormRoute, data, config);
    if (res) {
      if (res.status === 420) {
        return res;
      }
      if (res.status === 200) {
        setLoading(false);

        return res;
      } else {
        alert(res.data.message);
        setLoading(false);
      }
    } else {
      alert("Something went wrong");
      setLoading(false);
    }
  } catch (error: any) {
    setLoading(false);
    alert(error?.response?.data?.message);
  }
};

interface EditApplicationProps {
  id: string | null;
  name: string;
  form: string[];
}

export const editApplication = async ({
  id,
  name,
  form,
}: EditApplicationProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let aid = cookies.get("aid");
  let data = { id, name, form, aid };

  try {
    const res = await Axios.post(ROUTES.editFormRoute, data, config);
    if (res) {
      if (res.status === 420) {
        return res;
      }
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

interface getAllApplicationsProps {
  rowsPerPage: number;
  page: number;
  search?: string;
}

export const getAllApplications = async ({
  rowsPerPage,
  page,
  search,
}: getAllApplicationsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = { rowsPerPage, page, search };

  try {
    const res = await Axios.post(ROUTES.getAllFormRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

interface getSingleApplicationProps {
  id: string | null;
}

export const getSingleApplication = async ({
  id,
}: getSingleApplicationProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = { id };

  try {
    const res = await Axios.post(
      ROUTES.getSingleApplicationRoute,
      data,
      config
    );

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error);
  }
};

interface createTypesProps {
  name: string;
  form: string;
  showForm: boolean;
  otp: boolean;
  parent: boolean;
  source: boolean;
  website: boolean;
}

export const createType = async ({
  name,
  form,
  showForm,
  parent,
  otp,
  source,
  website,
}: createTypesProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = {
    name: name,
    form_id: form,
    aid: aid,
    requires_application: showForm,
    parent: parent,
    otp: otp,
    source: source,
    website: website,
  };

  try {
    const res = await Axios.post(ROUTES.createTypesRoute, data, config);
    if (res) {
      if (res.status === 420) {
        return res;
      }
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

interface getAllPartnerTypesProps {
  rowsPerPage: number;
  page: number;
}

export const getAllPartnerTypes = async ({
  rowsPerPage,
  page,
}: getAllPartnerTypesProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = {
    rowsPerPage,
    page,
  };

  try {
    const res = await Axios.post(ROUTES.getAllPartnerTypesRoute, data, config);
    if (res) {
      return res;
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

interface createSGProps {
  name: string;
  location: string;
  state: string;
}

export const createSG = async ({ name, location, state }: createSGProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = {
    name: name,
    location: location,
    aid: aid,
    state: state,
  };

  try {
    const res = await Axios.post(ROUTES.createSGroute, data, config);
    if (res) {
      if (res.status === 420) {
        return res;
      }
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

interface getAllSGProps {
  rowsPerPage: number;
  page: number;
}

export const getAllSG = async ({ rowsPerPage, page }: getAllSGProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = {
    rowsPerPage,
    page,
  };

  try {
    const res = await Axios.post(ROUTES.getAllSGroute, data, config);
    if (res) {
      return res;
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

interface createPartnerLeadsProps {
  name: string;
  location: string;
  state: string;
  ccod: string;
  mobile: string;
  email: string;
  type_id: string;
  apEmail?: string;
  leadSource?: string;
  sales_grp?: string[];
}

export const createPartnerLeads = async ({
  name,
  location,
  state,
  ccod,
  mobile,
  email,
  type_id,
  apEmail,
  leadSource,
  sales_grp,
}: createPartnerLeadsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = {
    FirstName: name,
    mx_city: location,
    aid: aid,
    mx_state: state,
    CountryCode: ccod,
    Phone: mobile,
    EmailAddress: email,
    type_id,
    apEmail,
    leadSource,
    sales_grp,
  };

  try {
    const res = await Axios.post(ROUTES.createPartnerLeads, data, config);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
      }
    } else {
      alert(
        "Sorry, the lead you're trying to create already is in process of onboarding or onboarded. Please reach out to the Admin if you need any assistance."
      );
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

export const rejectPartnerLead = async (id: string) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = {
    aid: aid,
    id: id,
  };

  try {
    const res = await Axios.post(ROUTES.rejectPartnerLead, data, config);
    if (res) {
      if (res.status === 420) {
        return res;
      }
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

interface sendApplicationtoUserProps {
  partner_lead_id: string;
}

export const sendApplicationtoUser = async ({
  partner_lead_id,
}: sendApplicationtoUserProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = {
    partner_lead_id,
    aid,
  };

  try {
    const res = await Axios.post(ROUTES.sendApplication, data, config);

    if (res) {
      if (res.status === 420) {
        return res;
      }
      if (res.status === 200) {
        return res;
      } else {
        alert(res.data.message);
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    console.log(error);
    alert(error?.response?.data?.message);
  }
};

interface getApplicationProps {
  rowsPerPage: number;
  page: number;
  status: number[];
  qType?: string;
  qstatus?: string;
  sDate?: string;
  eDate?: string;
  sOption?: string;
  search?: string;
  qSG?: string;
}

export const getApplications = async ({
  rowsPerPage,
  page,
  status,
  qType,
  qstatus,
  sDate,
  eDate,
  sOption,
  qSG,
  search,
}: getApplicationProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = {
    rowsPerPage,
    page,
    status,
    qType,
    qstatus,
    sDate,
    eDate,
    sOption,
    search,
    qSG,
  };

  try {
    const res = await Axios.post(ROUTES.getPartnerApplication, data, config);
    if (res) {
      return res;
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
  }
};

export const getApplicantSideApplication = async (user_aid?: string) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const partner_aid = user_aid || cookies.get("aid");

  let data = { partner_aid };

  try {
    const res = await Axios.post(
      ROUTES.getApplicantApplicationRoute,
      data,
      config
    );

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error);
  }
};

interface updateApplicationSlugProps {
  lastFilledBlockIndex: number;
  user_response: object | null;
  id: string | undefined;
  percent?: string;
}

export const updateApplicationSlug = async ({
  lastFilledBlockIndex,
  user_response,
  id,
  percent,
}: updateApplicationSlugProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");

  let data = { aid, lastFilledBlockIndex, user_response, id, percent };

  try {
    const res = await Axios.post(
      ROUTES.updateApplicationSlugRoute,
      data,
      config
    );

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

interface submitApplicationProps {
  id: string | undefined;
}

export const submitApplication = async ({ id }: submitApplicationProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");

  let data = { aid, id };

  try {
    const res = await Axios.post(ROUTES.submitApplicationRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

export const getFlagsList = async (id: string | undefined) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  let data = { applicationinfo_id: id };

  try {
    const res = await Axios.post(ROUTES.getFlagsRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

interface updateFlagsProps {
  id: string | undefined;
  flag: string[];
  comment: string;
}
// id: string | undefined
export const updateFlags = async ({ id, flag, comment }: updateFlagsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = { aid, applicationinfo_id: id, flag, comment };

  try {
    const res = await Axios.post(ROUTES.createFlagRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

interface rejectPartnerProps {
  id: string;
  comment?: string;
}
// id: string | undefined
export const rejectPartner = async ({
  id,
  comment = "",
}: rejectPartnerProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = { aid, applicationinfo_id: id, remark: comment };

  try {
    const res = await Axios.post(ROUTES.rejectPartnersRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

interface approvePartnerProps {
  id: string;
}
// id: string | undefined
export const approvePartner = async ({ id }: approvePartnerProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const aid = cookies.get("aid");
  let data = { aid, applicationinfo_id: id };

  try {
    const res = await Axios.post(ROUTES.approvePartnerRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

interface uploadAgreementProps {
  data: FormData;
}

export const uploadAgreement = async ({ data }: uploadAgreementProps) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.uploadAgreementRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

interface getAllPartnersProps {
  rowsPerPage: number;
  page: number;
  qtype?: string;
  sOption?: string;
  search?: string;
  qstatus?: string;
  qSG?: string;
}

export const getAllPartners = async ({
  rowsPerPage,
  page,
  qtype,
  sOption,
  search,
  qstatus,
  qSG,
}: getAllPartnersProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const data = {
    page: page,
    rowsPerPage: rowsPerPage,
    qtype,
    sOption,
    search,
    qSG,
    qstatus,
  };

  try {
    const res = await Axios.post(ROUTES.getAllPartners, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Somethin went wrong");
    console.log(error?.response?.data?.message);
  }
};

interface VerifyOTPandCreatePartnerProps {
  name: string;
  email: string;
  ccod: string;
  state: string;
  mobile: string;
  location: string;
  otp: string;
  admin: string;
  leader: string;
  subSource: string;
}

export const verifyOTPandCreatePartner = async ({
  name,
  email,
  ccod,
  state,
  mobile,
  location,
  otp,
  admin,
  leader,
  subSource,
}: VerifyOTPandCreatePartnerProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const data = {
    e_otp: otp,
    p_name: name,
    email,
    ccod,
    state,
    mobile,
    location,
    admin,
    leader,
    subSource,
  };

  try {
    const res = await Axios.post(
      ROUTES.verifyOTPAndCreatePartnerRoute,
      data,
      config
    );

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    return error?.response?.data?.message;
  }
};

interface getStudentLeadsProps {
  rowsPerPage: number;
  page: number;
  lsource?: string;
  email?: string;
  id?: string;
  sDate?: string;
  eDate?: string;
  leadStage?: string;
  search?: string;
  program?: string;
  owner?: string;
  aid?: string;
}

export const getStudentLeads = async ({
  rowsPerPage,
  page,
  lsource,
  email,
  id,
  sDate,
  eDate,
  leadStage,
  search,
  program,
  owner,
  aid,
}: getStudentLeadsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };

  const data = {
    rowsPerPage,
    page,
    lsource,
    email,
    partner_aid: aid,
    id,
    sDate,
    eDate,
    leadStage,
    search,
    program,
    owner,
  };

  try {
    const res = await Axios.post(ROUTES.getAllStudentLeadsRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Something went wrong.  " + error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const ps_getStudentLeads = async ({
  rowsPerPage,
  page,
  sDate,
  eDate,
  leadStage,
  search,
  program,
}: getStudentLeadsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at"),
    },
  };
  const aid = cookies.get("aid");
  const data = {
    rowsPerPage,
    page,
    aid,
    sDate,
    eDate,
    leadStage,
    search,
    program,
  };

  try {
    const res = await Axios.post(ROUTES.partnerSideLeadsRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert("Something went wrong.  " + error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const uploadFormFiles = async (data: FormData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.uploadFormFilesRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const sendReminder = async (id: string) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };
  const data = { id };
  try {
    const res = await Axios.post(ROUTES.sendReminderRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const inactivePartner = async (aid: string) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };
  const data = { partner_aid: aid };
  console.log(data);
  try {
    const res = await Axios.post(ROUTES.inactive_partnerRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const getProgramList = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.getProgramListRoute, {}, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const verifyAadhaar = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.verifyAadhaarRoute, {}, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

export const exportApplication = async (aid: string | undefined) => {
  const downloadFile = (res: any) => {
    const contentDisposition = res.headers["content-disposition"];
    let fileName = contentDisposition.split(";")[1].split("=")[1];

    console.log("File name : ", fileName);
    fileName = fileName.replaceAll('"', "");
    console.log("File name : ", fileName);

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  var btch_data: any = {
    url: ROUTES.exportApplicationRoute + `?partner_aid=${aid}`,
    method: "GET",
    responseType: "blob",
    headers: {
      "x-access-token": cookies.get("at"),
      // 'Content-Type': 'application/json',
      // Accept: 'application/json',
    },
  };

  axios(btch_data)
    .then((res) => {
      downloadFile(res);
    })
    .catch((err) => {
      alert("Some error occured while creating the csv file!");
      console.error(err);
    });
};

interface getAllUsersProps {
  rowsPerPage: number;
  page: number;
  qRole?: string;
}

export const getAllUsers = async ({
  rowsPerPage,
  page,
  qRole,
}: getAllUsersProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.getAllUserRoute,
      { rowsPerPage, page, qRole },
      config
    );

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
  }
};

interface vrfyUserOpsEmailProps {
  email: string;
}

export const vrfyUserOpsEmail = async ({ email }: vrfyUserOpsEmailProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.vrfyUserEmailRoute, { email }, config);
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Cannot create new ops user with this email");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface createopsUserProps {
  user_aid: string;
  sales_grp?: string[];
  role: string;
}

export const createopsUser = async ({
  user_aid,
  sales_grp,
  role,
}: createopsUserProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.createOpsUserRoute,
      { user_aid, sales_grp, role },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Cannot create new ops user with this email");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface deleteOpsUserProps {
  user_aid: string;
}

export const deleteOpsUser = async ({ user_aid }: deleteOpsUserProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.deleteOpsUserRoute,
      { user_aid },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Cannot delete user");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface getB2APartnersProps {
  rowsPerPage: number;
  page: number;
  qstatus?: string;
  qLeader?: string;
  search?: string;
}

export const getB2APartners = async (data: getB2APartnersProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.getB2APartnersRoute, data, config);

    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface adminOtpProps {
  admin: string;
  email: string;
  ccod: string;
  mobile: string;
  subSource: string;
}

export const adminOtp = async ({
  admin,
  email,
  ccod,
  mobile,
  subSource,
}: adminOtpProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.adminOtpRoute,
      { admin, email, ccod, mobile, subSource },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    console.log(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const adminOTPBluk = async (data: FormData) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.adminOtpBlukRoute, data, config);
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    console.log(error?.response?.data?.message);
    return error?.response?.data?.message;
  }
};

export const uploadPartners = async (data: FormData) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.uploadBulkPartnersRoute, data, config);
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Cannot upload these partners");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

export const getConsultantProfile = async (partner_aid: string) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.getConsultantProfileRoute,
      { partner_aid: partner_aid },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface getLeadRequestsProps {
  rowsPerPage: number;
  page: number;
}
export const getLeadRequests = async ({
  page,
  rowsPerPage,
}: getLeadRequestsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.getLeadRequestsRout5e,
      { page, rowsPerPage },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface getCRequestsProps {
  rowsPerPage: number;
  page: number;
  qprogram?: string;
  eDate?: string;
  sDate?: string;
  qstatus?: string;
  qUploadStatus?: string;
  search?: string;
  qleader?: string;
  qconsultant?: string;
}
export const getCRequests = async ({
  page,
  rowsPerPage,
  qprogram,
  sDate,
  eDate,
  qstatus,
  qUploadStatus,
  search,
  qleader,
  qconsultant,
}: getCRequestsProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.getCRequestsRoute,
      {
        page,
        rowsPerPage,
        qprogram,
        sDate,
        eDate,
        qstatus,
        qUploadStatus,
        search,
        qLeader: qleader,
        qConsultant: qconsultant,
      },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

interface createNewLeadProps {
  name: string;
  email: string;
  ccod: string;
  mobile: string;
  program: string;
  stage: string;
}
export const createNewLead = async ({
  name,
  email,
  ccod,
  mobile,
  program,
  stage,
}: createNewLeadProps) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.createNewLeadRoute,
      { name, email, ccod, mobile, program, stage },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

export const getRequestedLeads = async (
  request_id: string,
  rowsPerPage: number,
  page: number
) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.getSingleRequestLeadRoute,
      { request_id, rowsPerPage, page },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

export const uploadLeads = async (data: FormData) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(ROUTES.uploadLeadsRoute, data, config);
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Cannot upload these leads");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

export const updateRequest = async (
  remarks: string,
  id: string,
  action: string
) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.updateRequestRoute,
      { remarks, action, request_id: id },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

export const getuserDetails = async (id: string) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.getUserDetailsRoute,
      { partner_aid: id },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};

export const editSG = async (id: string, sgs: string[]) => {
  const config = {
    headers: {
      Accept: "application/json",
      "x-access-token": cookies.get("at"),
      "Access-Control-Allow-Origin": "*",
    },
  };

  try {
    const res = await Axios.post(
      ROUTES.editSGRoute,
      { partner_aid: id, sgs },
      config
    );
    console.log(res);
    if (res) {
      if (res.status === 200) {
        return res;
      } else {
        alert(res?.data?.message);
        return res;
      }
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error?.response?.data?.message);
    console.log(error);
  }
};
