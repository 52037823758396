import { useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import { sidebarMap } from "../../constants/constants";
import SentApplication from "../../components/TrackApplication/SentApplication";
import ReviewApplication from "../../components/TrackApplication/ReviewApplication";
import VerifiedApplication from "../../components/TrackApplication/VerifiedApplication";
import RejectApplication from "../../components/TrackApplication/RejectApplication";

const tabMap = {
  SEND: "SEND",
  REVIEW: "REVIEW",
  VERIFIED: "VERIFIED",
  REJECT: "REJECT",
};

interface TrackApplicationProps {
  permissions: Record<number, number[]> | undefined;
}

const TrackApplication: React.FC<TrackApplicationProps> = ({
  permissions,
}: TrackApplicationProps) => {
  const [active, setActive] = useState<string>(sidebarMap.TRACK_APPLICATION);
  const [activeTab, setActiveTab] = useState<string>(tabMap.SEND);

  const [sent, setSent] = useState<number>(0);
  const [review, setReview] = useState<number>(0);

  return (
    <div>
      <SideBar
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="tabs_list">
            <div
              className={`tabs ${activeTab === tabMap.SEND && `active_tab`}`}
              onClick={() => {
                setActiveTab(tabMap.SEND);
              }}
            >
              Send & Track Applications {sent ? <span> ({sent}) </span> : "(0)"}
            </div>
            <div
              className={`tabs ${activeTab === tabMap.REVIEW && `active_tab`}`}
              onClick={() => {
                setActiveTab(tabMap.REVIEW);
              }}
            >
              Review Application {review ? <span> ({review}) </span> : "(0)"}
            </div>
            <div
              className={`tabs ${
                activeTab === tabMap.VERIFIED && `active_tab`
              }`}
              onClick={() => {
                setActiveTab(tabMap.VERIFIED);
              }}
            >
              Verified Application
            </div>
            <div
              className={`tabs ${activeTab === tabMap.REJECT && `active_tab`}`}
              onClick={() => {
                setActiveTab(tabMap.REJECT);
              }}
            >
              Rejected Application
            </div>
          </div>

          {activeTab === tabMap.SEND && (
            <SentApplication setSent={setSent} setReview={setReview} />
          )}
          {activeTab === tabMap.REVIEW && (
            <ReviewApplication setSent={setSent} setReview={setReview} />
          )}
          {activeTab === tabMap.VERIFIED && (
            <VerifiedApplication setSent={setSent} setReview={setReview} />
          )}
          {activeTab === tabMap.REJECT && (
            <RejectApplication setSent={setSent} setReview={setReview} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackApplication;
