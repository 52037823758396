import { applicationOptions } from "../../constants/constants";

interface ProcessInterface {
  options: string;
  setOptions: React.Dispatch<React.SetStateAction<string>>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setField: React.Dispatch<React.SetStateAction<string>>;
  selectFields: string[];
  field: string;
  setSelectedFields: React.Dispatch<React.SetStateAction<string[]>>;
  allFields: string[] | undefined;
  comment: string;
  handleFinalDecision: () => Promise<void>;
}

const Process = ({
  options,
  setOptions,
  setComment,
  setField,
  selectFields,
  field,
  setSelectedFields,
  allFields,
  comment,
  handleFinalDecision,
}: ProcessInterface) => {
  return (
    <div className="v_a_header">
      <div className="optionBtns">
        <button
          className={
            options === applicationOptions.FLAG
              ? "optBtn activeOtpBtn"
              : "optBtn"
          }
          onClick={() => {
            setOptions(applicationOptions.FLAG);
            setComment("");
            setField("");
          }}
        >
          Flag and Send To Partner
        </button>
        <button
          className={
            options === applicationOptions.APPROVE
              ? "optBtn activeOtpBtn"
              : "optBtn"
          }
          onClick={() => {
            setOptions(applicationOptions.APPROVE);
            setComment("");
            setField("");
          }}
        >
          Approve
        </button>
        <button
          className={
            options === applicationOptions.REJECT
              ? "optBtn activeOtpBtn"
              : "optBtn"
          }
          onClick={() => {
            setOptions(applicationOptions.REJECT);
            setComment("");
            setField("");
          }}
        >
          Reject
        </button>
      </div>

      {options === applicationOptions.REJECT && (
        <div>
          <div className="v_a_header2">Remarks</div>

          <div className="flags">
            <div>
              <div className="mt-1">
                <textarea
                  className="fillup_form_inputs w-78 mt-1 h-100"
                  placeholder="Remarks...."
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* <div className="allflags">
            {allFlags && (
              <>
                <div className="v_a_header2">All Flags</div>

                {allFlags.map((elem, i) => {
                  const [key, value] = Object.entries(elem)[0];
                  return (
                    <div key={i}>
                      <span className="v_a_status">{key}</span>:{" "}
                      <span>{value}</span>
                    </div>
                  );
                })}
              </>
            )}
          </div> */}
          </div>
        </div>
      )}

      {options === applicationOptions.FLAG && (
        <div>
          <div className="v_a_header2">Flag and Send it to Partner</div>

          <div className="flags">
            <div className="mt-1">
              {selectFields.map((elem, i) => {
                return (
                  <span
                    key={i}
                    onClick={() => {
                      const update = selectFields.filter(
                        (elem1) => elem1 !== elem
                      );

                      setSelectedFields(update);
                    }}
                    className="flag_blocks"
                  >
                    {elem}
                    <img
                      src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                      alt="cross"
                      onClick={() => {}}
                      style={{ width: "6px" }}
                      className="ml-1"
                    />
                  </span>
                );
              })}
            </div>
            <div className="v_a_header3 mt-1">
              Select Fields you want to flag*
            </div>
            <div>
              <select
                className="fillup_form_inputs w-78 "
                required={true}
                value={field}
                style={{ background: "white" }}
                onChange={(e) => {
                  setField(e.target.value);
                  const prev = selectFields;

                  if (!prev.includes(e.target.value)) {
                    setSelectedFields([...prev, e.target.value]);
                  }
                }}
              >
                <option value="">Select Field to flag</option>
                {allFields &&
                  allFields.map((elem, i) => {
                    return (
                      <option value={elem} key={i}>
                        {elem}
                      </option>
                    );
                  })}
              </select>
              {selectFields.length > 0 && (
                <div className="mt-1">
                  <div className="fillup_form_input_head ">
                    Remarks (Max limit 300 words)*
                  </div>
                  <textarea
                    className="fillup_form_inputs w-78 mt-1 h-100"
                    placeholder="Comments...."
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            {/* <div className="allflags">
            {allFlags && (
              <>
                <div className="v_a_header2">All Flags</div>

                {allFlags.map((elem, i) => {
                  const [key, value] = Object.entries(elem)[0];
                  return (
                    <div key={i}>
                      <span className="v_a_status">{key}</span>:{" "}
                      <span>{value}</span>
                    </div>
                  );
                })}
              </>
            )}
          </div> */}
          </div>
        </div>
      )}

      {options && (
        <div className="mt-1 finallSubmitAdminbtn">
          <button
            className="form_next"
            onClick={() => {
              handleFinalDecision();
            }}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default Process;
