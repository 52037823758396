import TopBar from "../../components/Topbar";
import { sidebarMap } from "../../constants/constants";
import SideBar3 from "../../components/Sidebar3";
import { useNavigate } from "react-router-dom";
import { checkVisibility } from "../../utility/helper";

interface MasterProps {
  permissions: Record<number, number[]> | undefined;
}

const Master: React.FC<MasterProps> = ({ permissions }: MasterProps) => {
  const active = sidebarMap.PARTNER_PORTAL;

  const navigate = useNavigate();

  return (
    <div>
      <SideBar3 />
      <TopBar active={active} />
      <div className="main">
        <div className="master_cards">
          {checkVisibility({ permissions: permissions, resource: 14 }) && (
            <div
              className="master_card"
              onClick={() => {
                navigate("/user_permission");
              }}
            >
              <div className="master_pic">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/roundPpl.svg"
                  alt="ppl"
                />
              </div>
              <div className="master_head"> User Management</div>
            </div>
          )}

          <div
            className="master_card"
            onClick={() => {
              navigate("/partnerLeads");
            }}
          >
            <div className="master_pic">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/roundPpl.svg"
                alt="ppl"
              />
            </div>
            <div className="master_head">Partner Management</div>
          </div>

          {checkVisibility({ permissions: permissions, resource: 15 }) && (
            <div
              className="master_card"
              onClick={() => {
                navigate("/m_b2a_partner");
              }}
            >
              <div className="master_pic">
                <img
                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/roundPpl.svg"
                  alt="ppl"
                />
              </div>
              <div className="master_head">Consultant Management</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Master;
