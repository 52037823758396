interface checkVisibilityProps {
  permissions: Record<number, number[]> | undefined;
  resource: number;
  value?: any;
}

export const checkVisibility = ({
  permissions,
  resource,
}: checkVisibilityProps) => {
  if (!permissions) {
    return false;
  } else {
    if (!permissions[resource]) {
      return false;
    } else if (permissions[resource]?.indexOf(1) !== -1) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkDeepVisibility = ({
  permissions,
  value,
  resource,
}: checkVisibilityProps) => {
  if (!permissions) {
    return false;
  } else {
    if (!permissions[resource]) {
      return false;
    } else if (permissions[resource]?.indexOf(value) !== -1) {
      return true;
    } else {
      return false;
    }
  }
};

export const getDate = (date: string) => {
  const newDate = date?.split("T");

  return newDate?.[0];
};
