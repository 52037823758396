import { useEffect, useRef, useState } from "react";
import TopBar from "../../components/Topbar";
import { sidebarMap } from "../../constants/constants";

import {
  getApplicantSideApplication,
  inactivePartner,
} from "../../api/partner";
import { Application } from "../../types";
import Loader from "../../components/Loader";
import SideBar2 from "../../components/Sidebar2";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { RegistryWidgetsType } from "@rjsf/utils";
import { useNavigate } from "react-router-dom";

interface PartnerProfileProps {
  permissions: Record<number, number[]> | undefined;
}

const PartnerProfile: React.FC<PartnerProfileProps> = ({
  permissions,
}: PartnerProfileProps) => {
  const [active, setActive] = useState(sidebarMap.PARTNER_PROFILE);
  const [loading, setLoading] = useState<boolean>(false);
  const params = new URLSearchParams(window.location.search);
  let lsource = params.get("lsource") || undefined;
  let email = params.get("email") || undefined;
  let aid = params.get("aid") || "";

  const [formData, setFormData] = useState<object | null>();
  const [application, setApplication] = useState<Application | null>(null);
  const [name, setName] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [trigger, setTrigger] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleInactive = async () => {
    // add confirm
    if (
      window.confirm("Are you sure you want to mark this partner as inactive?")
    ) {
      setLoading(true);

      const response = await inactivePartner(aid);

      if (response?.status === 200) {
        alert("Partner Marked Inactive");
        setTrigger(!trigger);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getApplicantSideApplication(aid);
      if (response) {
        let blocks = response?.data?.data;
        setApplication(blocks.application);
        setName(blocks.name);
        setStatus(blocks.status);

        const user_resp = blocks.applicationInfo.user_response;

        let obj = {};
        for (const key in user_resp) {
          obj = { ...obj, ...user_resp[key] };
        }

        setFormData(obj);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, lsource, aid, trigger]);

  const FileUpload = (props: any) => {
    const { value } = props;

    const [load] = useState<boolean>(false);

    return (
      <span className="uploadPic">
        {load ? (
          <Loader />
        ) : (
          <>
            <label className="customUpload">
              <span className="uploadtext-1">Upload</span>
              <span className="uploadtext-2">&nbsp; files here</span>

              <span className="smallText_uploadfile ml-1">
                (File size should not exceed 5 MB. Document should be in PDF,
                JPEG, JPG format only.)
              </span>
              <input type={"file"} style={{ display: "none" }} disabled></input>
            </label>
            {value && (
              <div className="fileName">
                <a
                  href={value || ""}
                  target="_blank"
                  className="fileName"
                  rel="noreferrer"
                >
                  {value ? (
                    <>{new URL(value).pathname.replace("/", "")}</>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            )}
            {/* 
            {value && (
              <button
                className="fileRemove"
                onClick={() => {
                  onChange(undefined);
                }}
              >
                Remove
              </button>
            )} */}
          </>
        )}
      </span>
    );
  };
  const widgets: RegistryWidgetsType = {
    FileWidget: FileUpload,
  };

  return (
    <div>
      <SideBar2
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="breadcrumb">
            <span
              className="track_lead_bread"
              onClick={() => {
                navigate("/m_partner");
              }}
            >
              Mange Partners
            </span>
            <img
              src="https://cdn.pegasus.imarticus.org/pgBandF/ArrowBlack.svg"
              alt="profile"
              style={{ width: "4px" }}
              className="ml-1"
            />
            <span className="ml-1 partnerName">{name && name}</span>
          </div>
          <div className="application_header mt-1">All Partners</div>
          <hr />

          {loading ? (
            <Loader />
          ) : (
            <div>
              <div className="mt-1">
                {application?.app_block_schema_ids.map((elem, i) => {
                  return (
                    <div className="profile_form" key={i}>
                      <Form
                        schema={elem.Edituischema}
                        uiSchema={elem.Viewuischema}
                        formData={formData}
                        validator={validator}
                        widgets={widgets}
                        key={i}
                        noHtml5Validate
                        disabled
                      ></Form>
                    </div>
                  );
                })}
              </div>
              {status === 1 ? (
                <div className="inact">
                  {/* <button
                    className="inact_btn dark-green"
                    onClick={() => {
                      // exportToPdf();
                    }}
                  >
                    Export Application
                  </button> */}
                  <button
                    className="inact_btn"
                    onClick={() => {
                      handleInactive();
                    }}
                  >
                    Mark as Inactive
                  </button>
                </div>
              ) : (
                <div className="inact">
                  {/* <button
                    className="inact_btn dark-green"
                    onClick={() => {
                      // exportToPdf();
                    }}
                  >
                    Export Application
                  </button> */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerProfile;
