import { useEffect, useState } from "react";
import { createType, getAllApplications } from "../../api/partner";
import { Application } from "../../types";
import Loader from "../Loader";

interface CreateTypesProps {
  setCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateTypes: React.FC<CreateTypesProps> = ({
  setCreate,
}: CreateTypesProps) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [form, setForm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const [parent, setParent] = useState<boolean>(false);
  const [otp, setOtp] = useState<boolean>(false);
  const [source, setSource] = useState<boolean>(false);
  const [website, setWebsite] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const allApplications = async () => {
      const response = await getAllApplications({ rowsPerPage: 0, page: 0 });

      if (response) {
        let value = response?.data?.data;
        setApplications(value.applications);
        setLoading(false);
      }
    };
    allApplications();
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const fName = name.trim();

    if (fName) {
      const response = await createType({
        name: fName,
        form: form,
        showForm: showForm,
        parent: parent,
        otp: otp,
        source: source,
        website: website,
      });
      if (response?.status === 200) {
        setCreate(false);
      }
    } else {
      alert("Form Name cannot be empty");
    }

    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <button
              onClick={() => {
                setCreate(false);
              }}
              className="back_btn"
            >
              Back
            </button>
          </div>

          <form onSubmit={submitHandler} className="fillup_form">
            <div className="partner_leads_header">Create Partner Type</div>
            <hr />
            <div className="fillup_form_field">
              <div>Name</div>
              <input
                type="text"
                className="fillup_form_inputs w-78"
                placeholder="Type Name Here..."
                value={name}
                required={true}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            <div className="fillup_form_field">
              <div>Website Default</div>
              <select
                className="fillup_form_inputs w-78"
                required={true}
                value={website ? "true" : "false"}
                style={{ background: "white" }}
                onChange={() => {
                  setWebsite(!website);
                }}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <div className="fillup_form_field">
              <div>Requires OTP?</div>
              <select
                className="fillup_form_inputs w-78"
                required={true}
                value={otp ? "true" : "false"}
                style={{ background: "white" }}
                onChange={() => {
                  setOtp(!otp);
                }}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <div className="fillup_form_field">
              <div>Has Parent?</div>
              <select
                className="fillup_form_inputs w-78"
                required={true}
                value={parent ? "true" : "false"}
                style={{ background: "white" }}
                onChange={() => {
                  setParent(!parent);
                }}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <div className="fillup_form_field">
              <div>Requires Source?</div>
              <select
                className="fillup_form_inputs w-78"
                required={true}
                value={source ? "true" : "false"}
                style={{ background: "white" }}
                onChange={() => {
                  setSource(!source);
                }}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            <div className="fillup_form_field">
              <div>Requires Application?</div>
              <select
                className="fillup_form_inputs w-78"
                required={true}
                value={showForm ? "true" : "false"}
                style={{ background: "white" }}
                onChange={() => {
                  setShowForm(!showForm);
                  setForm("");
                }}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>

            {showForm && (
              <div className="fillup_form_field">
                <div>Select Application</div>
                <select
                  className="fillup_form_inputs w-78"
                  required={showForm}
                  style={{ background: "white" }}
                  onChange={(e) => {
                    setForm(e.target.value);
                  }}
                >
                  <option value="">Select Form .....</option>

                  {applications.length > 0 &&
                    applications.map((elem, i) => {
                      return (
                        <option key={i} value={elem._id}>
                          {elem.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}

            <button type="submit" className="login_btn w30">
              Submit
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default CreateTypes;
