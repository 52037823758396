import { useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import { sidebarMap } from "../../constants/constants";
import ViewAllTypes from "../../components/ManageTypes/ViewAllTypes";
import CreateTypes from "../../components/ManageTypes/CreateTypes";

interface ManageTypesProps {
  permissions: Record<number, number[]> | undefined;
}

const ManageTypes: React.FC<ManageTypesProps> = ({
  permissions,
}: ManageTypesProps) => {
  const [active, setActive] = useState<string>(sidebarMap.MANAGE_TYPES);
  const [create, setCreate] = useState<boolean>(false);

  return (
    <div>
      <SideBar
        permissions={permissions}
        setActive={setActive}
        active={active}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="partner_leads">
          {!create && <ViewAllTypes setCreate={setCreate} />}
          {create && <CreateTypes setCreate={setCreate} />}
        </div>
      </div>
    </div>
  );
};

export default ManageTypes;
