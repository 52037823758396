import { useEffect, useState } from "react";
import { getYourName, logout } from "../api/auth";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { nameSetter } from "../redux/reducers/profile";
import { useDispatch } from "react-redux";

interface TopBarProps {
  active: string;
}

const TopBar: React.FC<TopBarProps> = ({ active }: TopBarProps) => {
  const name = useSelector((state: RootState) => state.profile.name);
  const navigate = useNavigate();
  const [drop, setDrop] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!name) {
      const allTypes = async () => {
        const response = await getYourName();

        if (response?.status === 200) {
          dispatch(nameSetter(response.data.data.name));
        }
      };
      allTypes();
    }
  }, [name, dispatch]);

  return (
    <div className="topbar">
      <div className="topbar_info"> {active}</div>
      <div
        className="topbar_profile"
        onMouseEnter={() => {
          setDrop(true);
        }}
        onMouseLeave={() => {
          setDrop(false);
        }}
      >
        <img
          src="https://cdn.pegasus.imarticus.org/images/Profile-01.svg"
          alt="profile"
          className="topbar_pic"
        />
        <span>{name}</span>
        <img
          src="https://cdn.pegasus.imarticus.org/labs/down-arrow.svg"
          alt="arrow"
        />
      </div>
      {drop && (
        <div
          className="topbar_drop"
          onMouseEnter={() => {
            setDrop(true);
          }}
          onMouseLeave={() => {
            setDrop(false);
          }}
        >
          {/* <div>View Profile</div> */}
          <div
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;
