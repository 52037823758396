import { useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import { sidebarMap } from "../../constants/constants";

import ViewAllSalesGroup from "../../components/ManageSalesGroup/ViewAllSalesGroup";
import CreateSalesGroup from "../../components/ManageSalesGroup/CreateSalesGroup";

interface ManageSaleGroupProps {
  permissions: Record<number, number[]> | undefined;
}

const ManageSaleGroup: React.FC<ManageSaleGroupProps> = ({
  permissions,
}: ManageSaleGroupProps) => {
  const [active, setActive] = useState<string>(sidebarMap.MANAGE_SALE_GROUP);
  const [create, setCreate] = useState<boolean>(false);

  return (
    <div>
      <SideBar
        permissions={permissions}
        setActive={setActive}
        active={active}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="partner_leads">
          {!create && <ViewAllSalesGroup setCreate={setCreate} />}
          {create && <CreateSalesGroup setCreate={setCreate} />}
        </div>
      </div>
    </div>
  );
};

export default ManageSaleGroup;
